<template>

  <van-swipe :autoplay="3000" class="custom-swipe" :show-indicators="true">
    <!-- 自定义轮播项 -->
    <van-swipe-item class="custom-swipe-item">
      <weather/>
    </van-swipe-item>
    <van-swipe-item class="custom-swipe-item">
      <img src="@/assets/banner_便民信息.png" alt="image2" />
    </van-swipe-item>
    <van-swipe-item class="custom-swipe-item">
      <img src="../../../assets/banner_广告招租.png" alt="image3" />
    </van-swipe-item>
  </van-swipe>

</template>

<script>
import weather from "@/components/home/item/Weather";
export default {
  components:{
    weather,
  },

  methods: {

  }
}
</script>

<style scoped>



/* 设置轮播组件的固定高度 */
.custom-swipe {
  height: 32vw; /* 设置轮播组件的高度 */
  max-height: 25vh;
  margin: 0.4rem auto;
  width: 96vw;
  max-width: 96%;
  border-radius: 15px;
}

/* 设置每个轮播项的高度和内容自适应 */
.custom-swipe-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-swipe-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持图片的纵横比，同时确保填充整个容器 */
  border-radius: 15px; /* 保持图片与轮播容器相同的圆角 */
}

</style>
