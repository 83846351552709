<template>
  <div>
    <!-- 帖子列表容器 -->
    <div ref="postList" class="post-list">
      <van-cell-group>
        <PostItemCard
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
            :basePath="basePath"
        />
      </van-cell-group>
    </div>


    <!-- 分页控件 -->
    <van-pagination
        v-model="pageNumber"
        :total-items="totalPosts"
        :items-per-page="pageSize"
        @change="onPageChange"
        prev-text="上一页"
        next-text="下一页"
    />
  </div>
</template>

<script>
import {ref, reactive, onMounted, watch} from 'vue';
import PostItemCard from '@/components/post/item/PostItemCard';
import {getPostList} from '@/request/api/home';  // 数据获取方法

export default {
  components: {
    PostItemCard,
  },
  props: {
    basePath: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const posts = ref([]);
    const totalPosts = ref(0);
    const pageNumber = ref(5);
    const pageSize = ref(10);  // 每页显示的帖子数量

    // 数据获取方法
    const fetchData = () => {
      getPostList(props.basePath, pageNumber.value, pageSize.value)
          .then(response => {
            if (response && response.data.code === 0) {
              posts.value = response.data.data;
              totalPosts.value = response.data.count;
              console.log('Total Posts:', totalPosts.value);
            } else {
              console.error("请求失败:", response.data.message || "未知错误");
            }
          })
          .catch(error => {
            console.error("加载数据失败，发生未知错误");
          });
    };

    // 页码变化时触发数据重新加载
    const onPageChange = (newPage) => {
      pageNumber.value = newPage;
      fetchData();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // 初始化时加载数据
    onMounted(fetchData);

    // 监听 basePath 变化时重新加载数据
    watch(() => props.basePath, fetchData);



    return {
      posts,
      totalPosts,
      pageNumber,
      pageSize,
      onPageChange,
      fetchData,
    };
  },
};
</script>


<style>

</style>
