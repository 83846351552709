<template>
  <div>
<!--    <h1>当前模式：{{ localMode }}</h1>-->
<!--    <p>当前帖子 ID：{{ postId }}</p>-->
<!--    <p>当前板块：{{ basePath }}</p>-->

    <!-- 帖子内容组件，传递当前模式和 postId, basePath -->
    <FormComponent
        ref="FormComponent"
        :mode="localMode"
        :postId="postId"
        :basePath="basePath"
    />

    <!-- 操作按钮直接融入到父组件中 -->
    <div style="width: 60%;margin: 0 auto; /* 上下边距为 0，左右边距为自动，以此居中 */
  background-color: #f0f0f0; /* 背景颜色仅用于展示效果 */
  padding: 20px; /* 给元素添加一些内边距 */">
      <!-- 查看模式下显示“编辑”按钮 -->
      <van-button v-if="localMode === 'view'" type="primary" size="large" @click="enterEditMode">编辑帖子</van-button>

      <!-- 编辑模式下显示“保存”和“取消”按钮 -->
      <van-button v-if="localMode === 'edit'" type="success" @click="saveChanges">保存修改</van-button>
      <van-button v-if="localMode === 'edit'" type="default" @click="cancelEdit">取消修改</van-button>

      <!-- 创建模式下显示“发布”按钮 -->
      <van-button v-if="localMode === 'create'" type="success" size="large" @click="publishPost">发布帖子</van-button>
    </div>
  </div>
</template>

<script>

import {getPostById, sendPost, updatePost} from '@/request/api/home'; // 导入查询帖子详情的API
import JobHire_PostContentComponent from "@/components/post/jobs/JobHire_PostContentComponent";
import {handleApiError, handleApiResponse, handUpdateApiResponse} from "@/js/apiResponseHandler";
import router from "@/router";
import JobSeek_PostContentComponent from "@/components/post/jobs/JobSeek_PostContentComponent";
import DefaultForm from "@/components/views/DefaultForm.vue";
import { showDialog } from 'vant';

export default {
  components: {

    FormComponent: null,  // 初始化时为空，会动态设置
  },
  created() {
    this.setFormComponent();
  },

  props: {
    postId: { // 帖子 ID
      type: String,
      required: true,
    },
    basePath: { // 当前板块 (jobs, rentals 等)
      type: String,
      required: true,
    },
    mode: { // 当前模式: 'view', 'edit', 'create'
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localMode: this.mode, // 初始化模式
    };
  },
  methods: {
    // 动态设置 FormComponent，保证不同表单组件的引用名称一致
    setFormComponent() {

      switch (this.basePath) {
        case 'jobs-hire':
          this.$options.components.FormComponent = JobHire_PostContentComponent; // 设置为招聘表单
          break;
        case 'jobs-seek':
          this.$options.components.FormComponent = JobSeek_PostContentComponent; // 设置为求职表单
          break;
        case 'jobs':
          this.$options.components.FormComponent = JobHire_PostContentComponent; // 设置为求职表单
          break;
        case 'rentals-offer':
          this.$options.components.FormComponent = JobHire_PostContentComponent; // 设置为租房表单
          break;
        default:
          this.$options.components.FormComponent = DefaultForm; // 处理未找到的情况
      }
    },

    // 切换到编辑模式
    enterEditMode() {
      this.localMode = 'edit';
    },

    // 保存修改内容，获取子组件的数据
    async saveChanges() {
      const postData = this.$refs.FormComponent.getPostData();
      console.error(this.basePath)
      console.log('更新的帖子数据:', postData);
      // 此处可以进行发布逻辑，比如通过 API 发送 postData
      try {
        console.error(this.basePath)
        const response = await updatePost(this.basePath, this.postId,postData);
        handUpdateApiResponse(response, router, this.basePath);

      } catch (error) {
        handleApiError(error);
      }
      this.localMode = 'view'; // 切换回查看模式
      this.getPost();
    },

    // 取消编辑，返回查看模式
    cancelEdit() {
      console.log('已取消修改');
      this.localMode = 'view';
      this.getPost();
    },

    // 发布新帖子，获取子组件的数据
    publishPost() {
      showDialog({
        message: '请先登录账户',
      });
      // const postData = this.$refs.FormComponent.getPostData();
      // console.log('发布的新帖子数据:', postData);
      // // 此处可以进行发布逻辑，比如通过 API 发送 postData
      // try {
      //   const response = await sendPost(this.basePath, postData);
      //   handleApiResponse(response, router, this.basePath);
      // } catch (error) {
      //   handleApiError(error);
      // }
    },

    // 获取帖子数据并传递给子组件
    async getPost() {
      try {
        const response = await getPostById(this.basePath, this.postId); // 调用API获取帖子数据
        if (response.data && response.data.data) {
          const postDetails = response.data.data;
          // 通过子组件的方法将数据传递给子组件
          this.$refs.FormComponent.initializeContent(postDetails);
          console.log('帖子详情加载成功:', postDetails);
        }
      } catch (error) {
        console.error('加载帖子详情失败:', error);
      }
    }
  },
  mounted() {
    // 如果是 view 模式，调用 getPost 方法
    if (this.localMode === 'view') {
      this.getPost();
    }
  }
};
</script>

<style scoped>
div {
  font-size: 18px;
}
</style>
