<template>
  <div class="content">
    <!-- 规则 1 -->
    <div class="rule">
      <div class="rule-title">1. 传播范围和增加曝光度</div>
      <div class="rule-description">
        <p>该发布消息将在对应板块展示,并转发到群聊中,支持关键字搜索,并推送给关注者。</p>
        <p>发布者可以选择不同的 <span class="highlight">信息有效期,置顶,群聊传播次数</span> 来增加曝光度。</p>
      </div>
    </div>
    <van-divider class="custom-divider"/>

    <!-- 规则 2 -->
    <div class="rule">
      <div class="rule-title">2. 发布内容和条例</div>
      <div class="rule-description">
        <p>发布者需保证发布内容的真实性和有效性。</p>
        <p>内容应与发布板块息息相关,更多请查看
          <a href="/UserServiceTerms" class="privacy-link">《用户告知与隐私条例》</a>。
        </p>
      </div>
    </div>
    <van-divider class="custom-divider"/>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
/* 内容区域样式 */
.content {
  background: linear-gradient(135deg, #e0f7fa 0%, #d7c3fc 100%);
  border-radius: 1rem;
}

.rule-title {
  font-size: 1rem;
  font-weight: bold;
}

.rule-description {
  font-size: 0.5rem;
}

.highlight {
  color: red;
}

/* 自定义分隔线样式 */
.custom-divider {
  color: #1989fa;
  border-color: #1989fa;
  margin: 10px;
}

/* 隐私链接样式 */
.privacy-link {
  color: #0081ff;
  text-decoration: none;
}
</style>
