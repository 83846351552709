<template>
  <div class="post-form">
    <van-cell-group inset>

      <van-field
          v-model="postData.message"
          rows="4"
          autosize
          type="textarea"
          label="职位描述"
          placeholder="描述公司介绍，招聘岗位，工作地点、工作时间、假期,福利待遇，工资发放情况等"
          maxlength="300"
          show-word-limit
          required
          clearable
          :readonly="isReadOnly"
      />

      <TagSelector
          :tags="displayTags"
          :mode="mode"
          :selectedTags="postData.tags"
          @update:selectedTags="updateSelectedTags"
      />

      <van-field
          v-model="postData.location"
          label="工作地点"
          placeholder="例：重庆市江津区"
          required
          :readonly="isReadOnly"
      />

      <SalaryInput
          v-model:min="postData.salaryMin"
          v-model:max="postData.salaryMax"
          :error-message="salaryError"
          @validate="validateSalary"
          :readonly="isReadOnly"
          :mode="mode"
      />

      <van-field
          v-model="postData.nickname"
          label="联系人"
          placeholder="请输入联系人姓名"
          required
          :readonly="isReadOnly"
      />

      <van-field
          v-model="postData.tel"
          type="tel"
          label="联系电话"
          placeholder="请输入手机号"
          required
          :readonly="isReadOnly"
      />

      <!-- 图片展示 -->
      <div v-if="postData.images && mode === 'view'">
        <p>图片: {{ postData.images }}</p>
        <img :src="postData.images" alt="图片" width="100%"/>
      </div>

      <ImageUploader v-if="mode === 'edit' || mode === 'create'" @update:files="updateFiles"/>
    </van-cell-group>
  </div>
</template>

<script>
import {ref, reactive, computed, watch} from 'vue';
import {Field, CellGroup, showToast} from 'vant';
import {sendPost} from '@/request/api/home';
import {findTagsByName} from "@/js/postTags";

import TagSelector from "@/components/post/item/TagSelector.vue";

import {useRouter} from 'vue-router';
import {handleApiResponse, handleApiError} from '@/js/apiResponseHandler';
import PostHeader from "@/components/post/item/PostHeader";
import ImageUploader from "@/components/post/item/ImageUploader";
import SalaryInput from "@/components/post/item/SalaryInput";

export default {
  components: {
    Field,
    CellGroup,
    ImageUploader,
    TagSelector,
    SalaryInput,
    PostHeader,
  },
  props: {
    mode: {
      type: String,
      default: 'view', // 当前操作模式: 'view', 'edit', 'create'
    },
    postId: {
      type: String,
      required: true, // 帖子ID
    },
    basePath: {
      type: String,
      required: true, // 当前板块路径
    },
  },
  setup(props) {
    const router = useRouter();
    const postData = reactive({
      message: '',
      location: '',
      nickname: '',
      tel: '',
      salaryMin: '',
      salaryMax: '',
      tags: '',
      images: '',
    });

    const allTags = ref(findTagsByName('jobs-hire'));
    const displayTags = ref([]);
    const salaryError = ref('');
    const isSalaryValid = ref(true);
    const files = ref([]);

    const isReadOnly = computed(() => props.mode === 'view');

    const updateDisplayTags = () => {
      if (isReadOnly.value) {
        const selectedTagNames = postData.tags.split(',').filter(tag => tag.trim() !== '');
        displayTags.value = selectedTagNames.map(name => ({name, selected: true}));
      } else {
        displayTags.value = allTags.value.map(tag => ({
          ...tag,
          selected: postData.tags.split(',').includes(tag.name)
        }));
      }
    };

    watch(() => props.mode, (newMode) => {
      updateDisplayTags();
    });


    const updateSelectedTags = (newTags) => {
      postData.tags = newTags;
      updateDisplayTags();
    };

    const validateSalary = (isValid) => {
      isSalaryValid.value = isValid;
    };

    const validatePhone = (value) => {
      const pattern = /^1[3-9]\d{9}$/;
      return pattern.test(value);
    };

    const validateForm = () => {
      if (!postData.message.trim()) {
        showToast('请填写职位描述');
        return false;
      }
      if (!isSalaryValid.value) {
        showToast(salaryError.value || '薪资范围输入有误');
        return false;
      }
      if (!postData.location.trim()) {
        showToast('请填写工作地点');
        return false;
      }
      if (!postData.nickname.trim()) {
        showToast('请填写联系人姓名');
        return false;
      }
      if (!validatePhone(postData.tel)) {
        showToast('请输入有效的手机号');
        return false;
      }
      return true;
    };

    const updateFiles = (updatedFiles) => {
      files.value = updatedFiles;
      console.log('Updated files:', files.value);
    };

    const getPostData = () => {
      if (!validateForm()) return;

      const submitData = new FormData();

      Object.entries(postData).forEach(([key, value]) => {
        submitData.append(key, value);
      });

      files.value.forEach((file) => {
        if (file instanceof File) {
          submitData.append('images', file, file.name);
        } else {
          console.error('无效的文件对象:', file);
        }
      });

      return submitData;
    };

    const initializeContent = (data) => {
      Object.assign(postData, data);
      updateDisplayTags();
    };

    return {
      postData,
      displayTags,
      salaryError,
      isReadOnly,
      updateSelectedTags,
      validateSalary,
      getPostData,
      updateFiles,
      initializeContent,
      updateDisplayTags,
    };
  },
  mounted() {
    this.updateDisplayTags();
  },
};
</script>

<style scoped>
div {
  font-size: 18px;
}
</style>