import Compressor from 'compressorjs';
import {showToast} from "vant";

export function compressAndWatermarkImage(file) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            maxWidth: 1024,
            success: async (compressedResult) => {
                try {
                    const watermarkedFile = await addWatermark(compressedResult);
                    resolve(watermarkedFile);
                } catch (error) {
                    showToast('添加水印失败');
                    reject(error);
                }
            },
            error(err) {
                showToast('图片压缩失败');
                reject(err);
            }
        });
    });
}

function addWatermark(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                // 绘制原图
                ctx.drawImage(img, 0, 0, img.width, img.height);

                // 设置水印
                const watermarkText = '重庆酷喵喵科技有限公司';
                const fontSize = Math.max(12, Math.min(30, img.width * 0.04));
                ctx.font = `${fontSize}px Arial`;
                ctx.textBaseline = 'middle';
                ctx.textAlign = 'center';

                // 计算水印位置
                const padding = fontSize / 2;
                const x = canvas.width - ctx.measureText(watermarkText).width / 2 - padding;
                const y = canvas.height - fontSize / 2 - padding;

                // 绘制水印背景
                ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
                const textWidth = ctx.measureText(watermarkText).width;
                ctx.fillRect(x - textWidth / 2 - padding / 2, y - fontSize / 2 - padding / 2, textWidth + padding, fontSize + padding);

                // 绘制水印文字
                ctx.fillStyle = 'white';
                ctx.fillText(watermarkText, x, y);

                // 将 canvas 转换回文件
                canvas.toBlob((blob) => {
                    const watermarkedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now()
                    });
                    resolve(watermarkedFile);
                }, file.type);
            };
            img.onerror = reject;
            img.src = e.target.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}
