<template>
  <div class="post-form">
    <van-cell-group inset>
      <van-field
          v-model="postData.message"
          rows="3"
          autosize
          type="textarea"
          label="求职信息"
          placeholder="请描述您的求职意向"
          maxlength="300"
          show-word-limit
          required
          clearable
          :readonly="isReadOnly"
      />


      <van-field
          v-model="postData.nickname"
          required
          label="姓名"
          placeholder="请输入姓名"
          :readonly="isReadOnly"
      />
      <van-field
          v-model="postData.tel"
          type="tel"
          label="联系电话"
          placeholder="请输入手机号"
          required
          :readonly="isReadOnly"
      />

      <SalaryInput
          v-if="postData.location||!isReadOnly"
          v-model:min="postData.salaryMin"
          v-model:max="postData.salaryMax"
          :error-message="salaryError"
          @validate="validateSalary"
          :readonly="isReadOnly"
          :mode="mode"
      />

      <van-field
          v-if="postData.location||!isReadOnly"
          v-model="postData.location"
          :label="mode === 'view' ? '期待工作地点' : '工作地点'"
          placeholder="例：重庆主城附近"
          :readonly="isReadOnly"
      />

      <!-- 图片展示 -->
      <div v-if="postData.images && mode === 'view'">
        <p>图片: {{ postData.images }}</p>
        <img :src="postData.images" alt="图片" width="100%"/>
      </div>


      <van-collapse v-model="activeNames">
        <van-collapse-item :title="mode === 'view' ? '更多求职者相关信息' : '填写更多信息，更容易被老板看中'" name="0">

<!--          <van-field-->
<!--              readonly-->
<!--              clickable-->
<!--              name="gender"-->
<!--              label="性别"-->
<!--              :value="postData.gender ? '男' : '女'"-->
<!--              placeholder="请选择性别"-->
<!--              @click="showGenderPicker = true"-->
<!--          />-->
          <van-popup v-model:show="showGenderPicker" round position="bottom">
            <van-picker
                :columns="genderOptions"
                @cancel="showGenderPicker = false"
            />
          </van-popup>

          <!--            <van-field-->
          <!--                readonly-->
          <!--                clickable-->
          <!--                name="dateOfBirth"-->
          <!--                label="出生日期"-->
          <!--                :value="postData.dateOfBirth"-->
          <!--                placeholder="点击选择出生日期"-->
          <!--                @click="showDatePicker = true"-->
          <!--            />-->
          <van-popup v-model:show="showDatePicker" position="bottom">
            <van-date-picker
                @cancel="showDatePicker = false"
                :min-date="new Date(1950, 0, 1)"
                :max-date="new Date()"
            />
          </van-popup>





          <van-field
              readonly
              clickable
              name="availableStartDate"
              label="可入职日期"
              :value="postData.availableStartDate"
              placeholder="点击选择可入职日期"
              @click="showAvailableDatePicker = true"
              :readonly="isReadOnly"
          />
          <van-popup v-model:show="showAvailableDatePicker" position="bottom">
            <van-date-picker

                @cancel="showAvailableDatePicker = false"
                :min-date="new Date()"
            />
          </van-popup>

          <van-field
              v-model="postData.locationPreference"
              name="locationPreference"
              label="期望工作地点"
              placeholder="请输入期望的工作地点"
              :readonly="isReadOnly"
          />

          <van-field
              v-model="postData.educationBackground"
              rows="2"
              autosize
              label="教育背景"
              type="textarea"
              maxlength="500"
              placeholder="请描述您的教育背景"
              show-word-limit
              :readonly="isReadOnly"
          />

          <van-field
              v-model="postData.workExperience"
              rows="2"
              autosize
              label="工作经验"
              type="textarea"
              maxlength="1000"
              placeholder="请描述您的工作经验"
              show-word-limit
              :readonly="isReadOnly"
          />

          <van-field
              v-model="postData.skills"
              rows="2"
              autosize
              label="技能"
              type="textarea"
              maxlength="500"
              placeholder="请列出您的技能"
              show-word-limit
              :readonly="isReadOnly"
          />

          <van-field name="showPersonalResume" label="展示个人简历">
            <template #input>
              <van-switch v-model="postData.showPersonalResume" size="20"/>
            </template>
          </van-field>

          <ImageUploader v-if="mode === 'edit' || mode === 'create'" @update:files="updateFiles"/>
        </van-collapse-item>
      </van-collapse>



    </van-cell-group>






  </div>
</template>

<script>
import {ref, reactive, computed, watch, nextTick} from 'vue';
import {Field, CellGroup, showToast} from 'vant';
import {sendPost} from '@/request/api/home';
import {findTagsByName} from "@/js/postTags";
import ImageUploader from '@/components/post/item/ImageUploader.vue';
import SalaryInput from "@/components/post/item/SalaryInput.vue";
import {useRouter} from 'vue-router';
import {handleApiResponse, handleApiError} from '@/js/apiResponseHandler';
import TagSelector from "@/components/post/item/TagSelector";

export default {
  components: {
    Field,
    CellGroup,
    ImageUploader,
    TagSelector,
    SalaryInput
  },
  props: {
    mode: {
      type: String,
      default: 'view', // 当前操作模式: 'view', 'edit', 'create'
    },
    postId: {
      type: String,
      required: true, // 帖子ID
    },
    basePath: {
      type: String,
      required: true, // 当前板块路径
    },
  },
  setup(props) {
    const router = useRouter();
    const postData = reactive({
      message: '',
      location: '',
      nickname: '',
      tel: '',
      salaryMin: '',
      salaryMax: '',

      images: '',
    });


    const salaryError = ref('');
    const isSalaryValid = ref(true);
    const files = ref([]);
    const isReadOnly = computed(() => props.mode === 'view');
    const showDatePicker = ref(false);
    const showAvailableDatePicker = ref(false);
    const showGenderPicker = ref(false);
    const activeNames = ref([]); // 初始化为收起状态
    const genderOptions = [
      {text: '男', value: true},
      {text: '女', value: false}
    ];


    const validateSalary = (isValid) => {
      isSalaryValid.value = isValid;
    };

    const validatePhone = (value) => {
      const pattern = /^1[3-9]\d{9}$/;
      return pattern.test(value);
    };

    const validateForm = () => {
      if (!postData.message.trim()) {
        showToast('请填写职位描述');
        return false;
      }
      if (!isSalaryValid.value) {
        showToast(salaryError.value || '薪资范围输入有误');
        return false;
      }
      if (!postData.location.trim()) {
        showToast('请填写工作地点');
        return false;
      }
      if (!postData.nickname.trim()) {
        showToast('请填写联系人姓名');
        return false;
      }
      if (!validatePhone(postData.tel)) {
        showToast('请输入有效的手机号');
        return false;
      }
      return true;
    };

    const updateFiles = (updatedFiles) => {
      files.value = updatedFiles;
      console.log('Updated files:', files.value);
    };

    const getPostData = () => {
      if (!validateForm()) return;

      const submitData = new FormData();

      Object.entries(postData).forEach(([key, value]) => {
        submitData.append(key, value);
      });

      files.value.forEach((file) => {
        if (file instanceof File) {
          submitData.append('images', file, file.name);
        } else {
          console.error('无效的文件对象:', file);
        }
      });

      return submitData;
    };

    const initializeContent = (data) => {
      Object.assign(postData, data);
    };


    return {
      activeNames,
      postData,
      salaryError,
      isReadOnly,
      validateSalary,
      getPostData,
      updateFiles,
      initializeContent,

      showDatePicker,
      showAvailableDatePicker,
      showGenderPicker,
      genderOptions,

    };
  },
  mounted() {
    nextTick(() => {
      // 在 DOM 更新后根据 mode 判断是否展开面板
      if (this.isReadOnly) {
        this.activeNames = ['0']; // 收起面板
      } else {
        this.activeNames = []; // 展开面板
      }
    });
  },
};
</script>

<style scoped>
div {
  font-size: 18px;
}
</style>