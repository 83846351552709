<template>
  <div class="chat-container">
    <!-- 聊天记录展示 -->
    <div class="chat-messages">
      <div
          v-for="(msg, index) in chatMessages"
          :key="index"
          :class="{'self-message': msg.userId === currentUserId}"
      >
        <div class="message-bubble">
          <span class="username">{{ msg.userId === currentUserId ? '我' : msg.username }}</span>
          <div class="message-content">{{ msg.message }}</div>
        </div>
      </div>
    </div>

    <!-- 消息输入框和发送按钮 -->
    <div class="chat-input">
      <van-field v-model="message" placeholder="请输入消息" />
      <van-button type="primary" @click="sendMessage">发送</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socket: null,          // WebSocket 实例
      currentUserId: 'userxx', // 当前用户ID，模拟登录用户
      message: '',           // 当前输入的消息
      chatMessages: [],      // 聊天记录
      isSocketReady: false,  // 标志 WebSocket 是否已准备好
      retryInterval: null,   // 重试发送消息的定时器
      sendingMessage: false, // 防止重复发送消息
    };
  },
  mounted() {
    this.connectWebSocket();
  },
  methods: {
    // 建立 WebSocket 连接
    connectWebSocket() {
      this.socket = new WebSocket(`ws://localhost:9981/chat?userId=${this.currentUserId}`);

      // 连接成功时
      this.socket.onopen = () => {
        this.isSocketReady = true;
        console.log('WebSocket 连接成功');
      };

      // 接收到消息时，将其添加到聊天记录
      this.socket.onmessage = (event) => {
        const receivedMsg = JSON.parse(event.data);
        this.chatMessages.push(receivedMsg);
      };

      // 连接关闭时重试连接
      this.socket.onclose = () => {
        this.isSocketReady = false;
        console.log('WebSocket 连接关闭');
        this.retryConnection();
      };
    },

    // 发送消息
    sendMessage() {
      if (!this.isSocketReady || !this.message.trim()) {
        this.retrySendingMessage();
        return;
      }

      if (this.sendingMessage) {
        return; // 防止重复发送
      }

      // 发送消息，附带当前用户信息
      const data = {
        userId: this.currentUserId,
        username: '我',
        message: this.message,
      };

      this.sendingMessage = true;
      this.socket.send(JSON.stringify(data));
      this.chatMessages.push(data); // 发送成功后本地显示消息
      this.message = ''; // 发送后清空输入框

      // 允许再次发送消息
      setTimeout(() => {
        this.sendingMessage = false;
      }, 500); // 防止快速重复点击
    },


    // 重试 WebSocket 连接
    retryConnection() {
      setTimeout(() => {
        console.log('尝试重新连接 WebSocket');
        this.connectWebSocket();
      }, 5000);
    },
  },
};
</script>

<style scoped>
.chat-container {
  padding: 20px;
}

.chat-messages {
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.message-bubble {
  max-width: 60%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
}

.self-message {
  text-align: right;
}

.self-message .message-bubble {
  background-color: #daf8cb; /* 自己的消息背景色 */
  margin-left: auto;
}

.message-bubble {
  background-color: #f1f1f1; /* 他人的消息背景色 */
  border: 1px solid #ddd;
}

.message-content {
  word-break: break-word;
}

.username {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
