<template>
  <div className="post-header">
    <img :src="avatarSrc" class="user-avatar" :alt="username"/>
    <span class="username">{{ username }}</span>
    <div class="createdAT">&nbsp;&nbsp;{{ formattedCreatedAt }}</div>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    avatarSrc: {
      type: String,
      default: require('@/assets/user.jpg') // 默认头像路径
    }
  },
  computed: {
    formattedCreatedAt() {
      const date = new Date(this.createdAt);
      const year = date.getFullYear().toString().slice(2); // 取年份的后两位
      const month = date.getMonth() + 1; // 月份从0开始，所以要加1
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0'); // 确保分钟是两位数
      return `${year}年${month}月${day}日 ${hours}:${minutes}`;
    }
  }
};
</script>

<style scoped>
.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  font-size: 0.9rem;
  color: #2d2d2d;
}

.createdAT {
  font-size: 0.9rem;
  color: #858585;
}
</style>
