<template>
  <div>
    <div>xxx</div>
    <div v-if="announcement" class="announcement-popup">
      <!-- 公告内容弹窗 -->
      <van-popup v-model:show="showPopup">
        <div class="announcement-content">
          <h2>{{ announcement.title }}</h2>
          <p>{{ announcement.content }}</p>
          <van-button type="primary" @click="markAsRead">我知道了</van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getAnnouncementTest } from "@/request/api/home";
import { showToast } from "vant";

export default {
  setup() {
    const announcement = ref(null); // 存储当前公告
    const showPopup = ref(false);   // 控制弹窗显示

    // 从后端获取公告
    const fetchAnnouncement = async () => {
      try {
        const response = await getAnnouncementTest();
        if (response && response.data) {
          const data = response.data;
          // 先检查是否已经看过
          if (!hasSeenAnnouncement(data.id)) {
            announcement.value = data; // 设置公告内容
            showPopup.value = true; // 显示公告弹窗
          }
        } else {
          console.error("公告数据为空或请求失败");
        }
      } catch (error) {
        console.error("加载公告失败:", error);
      }
    };

    // 检查是否已经看过公告
    const hasSeenAnnouncement = (announcementId) => {
      // 获取已读的公告 ID，并检查是否包含当前公告 ID
      const seenAnnouncements = JSON.parse(localStorage.getItem('seenAnnouncements')) || [];
      return seenAnnouncements.includes(announcementId);
    };

    // 标记公告为已读
    const markAsRead = () => {
      // 每次只保存最新已读的公告ID，清除之前已读的公告ID
      localStorage.setItem('seenAnnouncements', JSON.stringify([announcement.value.id]));
      showPopup.value = false; // 关闭弹窗
    };

    onMounted(() => {
      fetchAnnouncement(); // 页面加载时获取公告
    });

    return {
      announcement,
      showPopup,
      markAsRead,
    };
  },
};
</script>

<style scoped>
.announcement-popup {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.announcement-content {
  padding: 20px;
  text-align: center;
}
</style>
