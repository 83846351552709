<template>
  <div class="main-view">
    <div class="item" v-for="(item, index) in items" :key="index" @click="navigateTo(item.url)">
      <div class="item-content" :style="{ backgroundColor: item.backgroundColor }">
        <img :src="item.image" alt="icon" class="item-image" />
        <p class="item-text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainView',
  data() {
    return {
      items: [
        { image: require('@/assets/商城.svg'), text: '商城', backgroundColor: '#b9ecff', url: 'kumiaomiao.top/buyer' },
        { image: require('@/assets/商户管理.svg'), text: '商户端', backgroundColor: '#a4dfd4', url: 'kumiaomiao.top/seller' },
        { image: require('@/assets/管理员.svg'), text: '管理端', backgroundColor: '#a4a4df', url: 'kumiaomiao.top/manager' }
      ]
    };
  },
  methods: {
    navigateTo(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.main-view {
  display: flex;
  justify-content: space-between; /* 平均分配宽度 */
  align-items: center;
  gap: 30px; /* 设置间隔 */
  width: 100%;
  max-width: 1200px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  padding: 0 15px; /* 给左右留出一点空间 */
  margin-bottom: 10px;
}

.item {
  flex-grow: 1; /* 平均分配宽度 */
  display: flex;
  justify-content: center; /* 居中对齐内容 */
  cursor: pointer; /* 鼠标悬停时显示手型光标 */
}

.item-content {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px; /* 圆角 */
  width: 100%; /* 确保内容宽度占满 */
  box-sizing: border-box; /* 包含内边距和边框 */
}

.item-image {
  width: 80px;
  height: 80px;
  border-radius: 10%; /* 图片圆角 */
}

.item-text {
  margin-left: 10px; /* 图片和文字之间的间距 */
  font-size: 3em;
  text-align: left;
}
</style>
