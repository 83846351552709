<template>
  <div class="data-list">
    <DataItem v-for="item in dataList" :key="item.id" :item="item"/>
  </div>
</template>

<script>
import DataItem from './DataItem.vue';
import {dataList }from '@/js/dataList'; // 确保路径正确

export default {
  name: 'DataList',
  components: {
    DataItem
  },
  data() {
    return {
      dataList // 引入数据列表
    };
  }
}
</script>

<style scoped>
.data-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
