<!-- JobComponent.vue -->
<template>
  <div>
    <van-cell-group>
      <van-field v-model="postContent" label="帖子内容：" />

      <van-divider />
      <p v-if="localMode !== 'create'">显示帖子详情（ID：{{ postId }}）</p>

      <!-- 编辑按钮在 view 模式下显示 -->
      <van-button v-if="localMode === 'view'" type="primary" @click="enterEditMode">编辑帖子</van-button>

      <!-- 保存和取消按钮在 edit 模式下显示 -->
      <van-button v-if="localMode === 'edit'" type="success" @click="saveChanges">保存更改</van-button>
      <van-button v-if="localMode === 'edit'" type="default" @click="cancelEdit">取消</van-button>

      <!-- 新增模式下显示发布按钮 -->
      <van-button v-if="localMode === 'create'" type="success" @click="publishPost">发布帖子</van-button>
    </van-cell-group>
  </div>
</template>

<script>
import { Field, CellGroup, Divider, Button, showToast } from 'vant';

export default {
  props: {
    postId: {
      type: String,
      required: false,
    },
    basePath: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      default: 'view', // 默认显示为查看模式
    },
  },
  data() {
    return {
      postContent: '', // 默认的帖子内容，新增模式下为空
      localMode: this.mode, // 本地管理的模式，初始值为父组件传来的 mode
    };
  },
  methods: {
    // 进入编辑模式
    enterEditMode() {
      this.localMode = 'edit'; // 切换到 edit 模式
    },

    // 保存更改，模拟提交
    saveChanges() {
      showToast({
        type: 'success',
        message: '修改已保存！',
        onClose: () => {
          this.localMode = 'view'; // 提交后返回查看模式
        }
      });
      console.log('提交的数据:', this.postContent); // 打印提交的内容
    },

    // 取消编辑，回到查看模式
    cancelEdit() {
      showToast({
        type: 'info',
        message: '已取消修改',
        onClose: () => {
          this.localMode = 'view'; // 点击取消按钮后返回查看模式
        }
      });
    },

    // 发布帖子，模拟新增功能
    publishPost() {
      if (this.postContent.trim() === '') {
        showToast({
          type: 'fail',
          message: '帖子内容不能为空',
        });
        return;
      }
      showToast({
        type: 'success',
        message: '帖子发布成功！',
        onClose: () => {
          this.localMode = 'view'; // 发布后返回查看模式
        }
      });
      console.log('发布的帖子内容:', this.postContent); // 打印发布的帖子内容
    },
  },
  watch: {
    // 监听父组件传递的 mode 变化，如果有变化，更新本地的 localMode
    mode(newMode) {
      this.localMode = newMode;
    },
  },
  components: {
    Field,
    CellGroup,
    Divider,
    Button,
    showToast,
  },
};
</script>

<style scoped>
/* 将所有字体放大 */
div {
  font-size: 18px; /* 调整整体字体大小 */
}
</style>
