 const postTags = [
    {
        id: 0,
        name: 'jobs-hire',
        tags: [
            {id: 1, name: '夜班', selected: false},
            {id: 2, name: '白班', selected: false},
            {id: 3, name: '长夜班', selected: false},
            {id: 4, name: '周末双休', selected: false},
            {id: 5, name: '五险一金', selected: false},
            {id: 6, name: '包吃', selected: false},
            {id: 7, name: '包住', selected: false},
            {id: 8, name: '买保险', selected: false},
            {id: 9, name: '餐补', selected: false},
            {id: 10, name: '长期工', selected: false},
            {id: 11, name: '临时工', selected: false},
            {id: 12, name: '加班补助', selected: false},
            {id: 13, name: '节假日', selected: false}
        ]
    },
    {
        id: 1,
        name: 'jobs-seek',
        tags: [
            {id: 1, name: '全职', selected: false},
            {id: 2, name: '兼职', selected: false},
            {id: 3, name: '实习', selected: false},
            {id: 4, name: '外包', selected: false}
        ]
    }
];

// 查找方法
export function findTagsByName(name) {
    const found = postTags.find(item => item.name === name);
    return found ? found.tags : null;  // 如果找到则返回tags, 否则返回null
}