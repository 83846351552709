<template>
  <div class="post-form">
    <van-form style="width: 100%" @submit="submitPost" colon="true">
      <van-cell-group inset>
        <!-- House Description -->
        <van-field
            v-model="description"
            rows="4"
            type="textarea"
            label="房源描述"
            placeholder="描述房子的基本信息、面积、房型、楼层、位置等"
            maxlength="300"
            show-word-limit
            required
            clearable
        />

        <!-- House Price -->
        <van-field name="price" label="价格">
          <template #input>
            <div class="price-input-container">
              <input v-model="price" type="number" placeholder="请输入价格" class="price-input" />
              <span class="price-unit">元</span>
            </div>
          </template>
        </van-field>

        <!-- House Location -->
        <van-field v-model="location" label="房子地点" placeholder="例：重庆市江津区xxxx" />

        <!-- House Type -->
        <van-field v-model="houseType" label="房型" placeholder="例：三室两厅" />

        <!-- House Area -->
        <van-field v-model="area" label="面积" placeholder="例：120 平方米" />

        <!-- Contact Info -->
        <van-field v-model="nickname" label="联系人" placeholder="请输入联系人姓名" required />
        <van-field v-model="tel" type="tel" label="联系电话" placeholder="请输入手机号" required />

        <!-- 图片上传功能 -->
        <div class="field-wrapper">
          <div class="field-label">上传图片</div>
          <van-uploader
              v-model="fileList"
              :before-read="beforeRead"
              multiple
              max-count="5"
              accept="image/*"
          />
          <div class="upload-tip">最多上传5张图片，将自动压缩至1MB以下</div>
        </div>
      </van-cell-group>

      <div class="submit-button-wrapper">
        <van-button round block type="primary" native-type="submit">
          发布房源信息
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Compressor from 'compressorjs';
import { postHouseSale } from '@/request/api/home';  // 引入你的 API 请求函数
import { showToast } from 'vant';

export default {
  props: {
    basePath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      description: '',
      price: '',
      location: '',
      houseType: '',
      area: '',
      nickname: '',
      tel: '',
      fileList: []
    };
  },
  methods: {
    beforeRead(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1024,
          success: async (compressedResult) => {
            resolve(compressedResult);
          },
          error(err) {
            showToast('图片压缩失败');
            reject(err);
          }
        });
      });
    },

    submitPost() {
      // 验证表单数据并提交数据
      if (!this.validateForm()) return;

      const formData = new FormData();
      formData.append('description', this.description);
      formData.append('price', this.price);
      formData.append('location', this.location);
      formData.append('houseType', this.houseType);
      formData.append('area', this.area);
      formData.append('nickname', this.nickname);
      formData.append('tel', this.tel);

      // 修改后，添加文件列表
      this.fileList.forEach((file, index) => {
        if (file.file instanceof File) {
          formData.append('images', file.file, file.file.name);
        } else {
          console.error('Invalid file object:', file);
        }
      });

      // 调用上传接口
      postHouseSale(this.basePath, formData)
          .then(response => {
            if (response && response.data) {
              showToast({
                type: 'success',
                message: '房源信息发布成功',
                onClose: () => {
                  this.$router.push({ name: 'PostSuccess' });
                }
              });
            } else {
              showToast('发布成功，但未收到服务器响应数据');
            }
          })
          .catch(error => {
            if (error.response && error.response.data) {
              showToast({
                type: 'fail',
                message: '发布失败：' + error.response.data
              });
            } else {
              showToast({
                type: 'fail',
                message: '发布失败，出现未预期的错误。'
              });
            }
          });
    },

    validateForm() {
      if (!this.description.trim()) {
        showToast('请填写房源描述');
        return false;
      }
      if (!this.price) {
        showToast('请填写房源价格');
        return false;
      }
      if (!this.location.trim()) {
        showToast('请填写房源地点');
        return false;
      }
      if (!this.houseType.trim()) {
        showToast('请填写房型');
        return false;
      }
      if (!this.area) {
        showToast('请填写房屋面积');
        return false;
      }
      if (!this.nickname.trim()) {
        showToast('请填写联系人姓名');
        return false;
      }
      if (!this.validatePhone(this.tel)) {
        showToast('请输入有效的手机号');
        return false;
      }
      return true;
    },

    validatePhone(value) {
      const pattern = /^1[3-9]\d{9}$/;
      return pattern.test(value);
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}

.field-wrapper {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
}

.price-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.price-input {
  width: 150px;
  height: 30px;
  border: 1px solid #dcdee0;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: #323233;}

:focus {
   border-color: #1989fa;
   outline: none;
 }


.price-unit {
  margin-left: 8px;
  color: #969799;
  font-size: 14px;
}

.upload-tip {
  font-size: 14px;
  color: #969799;
  margin-top: 8px;
}

.submit-button-wrapper {
  margin-top: 24px;
  width: 100%;
}
</style>
