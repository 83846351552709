import { Swipe, SwipeItem,Button,Popup,
    Tabbar, TabbarItem,Grid, GridItem,Card,
    Sticky,Search,Icon, Divider, IndexBar, IndexAnchor,
    Cell, CellGroup,RollingText,Field,
    Tag,NavBar,Picker,Uploader,Toast,Form,
    PullRefresh,List,Tab,Tabs,Pagination,DatePicker,Collapse, CollapseItem,
    BackTop,
} from 'vant';
// 放入数组中
let plugins=[
    Swipe,SwipeItem,Button,Popup, Tabbar, TabbarItem,
    Grid, GridItem,Card, Sticky,Search,Icon, Divider, IndexBar, IndexAnchor,
    Cell, CellGroup,RollingText,Field,Tag,
    NavBar,Picker,Uploader,Toast,Form,
    PullRefresh,List,Tab,Tabs,Pagination,DatePicker, Collapse, CollapseItem,
    BackTop,
]
export default function getVant(app){
    plugins.forEach((item)=>{
        return app.use(item)
    })
}
