<template>
  <div class="register-container">
    <h2>注册</h2>
    <van-field
        v-model="email"
        label="邮箱"
        placeholder="请输入邮箱"
        required
        type="email"
    />
    <van-field
        v-model="username"
        label="用户名"
        placeholder="请输入用户名"
        required
    />
    <van-field
        v-model="password"
        label="密码"
        type="password"
        placeholder="请输入密码"
        required
    />
    <van-button type="primary" @click="handleRegister">注册</van-button>
  </div>
</template>

<script>
import { Dialog,showDialog } from 'vant';


export default {
  data() {
    return {
      email: '',
      username: '',
      password: ''
    };
  },
  methods: {
    handleRegister() {
      // 检查是否填写完整
      if (!this.email || !this.username || !this.password) {
        showDialog({
          message: '请填写完整信息',
        });
        return;
      }

      // 显示确认对话框
      showDialog({
        title: '提示',
        message: '注册信息已登记，等待社区管理者审核后使用',
      })
          .then(() => {
            // 用户点击确认按钮后的处理
            this.$router.push('/personalInfo'); // 跳转到登录页面
          })
          .catch(() => {
            // 用户点击取消按钮后的处理
            // 这里可以不做任何处理
          });
    }
  }
};
</script>

<style scoped>
.register-container {
  padding: 20px;
  max-width: 400px;
  margin: auto;
}
</style>
