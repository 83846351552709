<template>
  <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="goBack"
  />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题' // 设置默认标题
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1); // 点击返回按钮后返回上一页
    }
  }
};
</script>

<style scoped>
/* 添加一些简单的样式可以根据需求调整 */
</style>
