<template>
  <div class="data-detail">
    <h4 class="centered">{{ item.title }}</h4>
    <h3>板块 {{ item.section }}</h3>
    <p v-html="formattedContent"></p>
    <small>{{ item.date }}</small>
    <div class="data-detail-images">
      <img v-for="(image, index) in item.images" :key="index" :src="image" alt="Data Image" class="data-detail-image"/>
    </div>
    <button @click="goBack">返回</button>
  </div>
</template>

<script>
export default {
  name: 'DataDetail',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedContent() {
      // 将内容中的换行符替换为 <br> 标签
      return this.item.content.replace(/\n/g, '<br>');
    }
  },
  methods: {
    goBack() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.data-detail {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.data-detail-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.data-detail-image {
  width: 200px;
  height: 200px;
}

.centered {
  text-align: center; /* 使标题居中 */
}

.data-detail h3 {
  margin: 5px 0;
  font-size: 1.8em;
  color: #427585; /* 设置板块名称的文字颜色 */
}

.data-detail h4 {
  margin: 5px 0;
  font-size: 2em;
}

.data-detail p {
  margin: 10px 0; /* 增加上下间距 */
  font-size: 1.5em;
  line-height: 1.6; /* 设置行高以扩大文字之间的间隔 */
  white-space: pre-wrap; /* 保留空白符号并支持换行 */
}

.data-detail small {
  color: #888;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
</style>
