<template>
  <NavigationBar title="服务条款内容"/>
  <div class="terms-page">
    <van-cell-group class="terms-content">
      <van-cell v-for="(item, index) in terms" :key="index" class="custom-cell">
        <div class="cell-title">{{ item.title }}</div>
        <div class="content">{{ item.content }}</div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import NavigationBar from "@/components/views/NavigationBar";

export default {
  components: {
    NavigationBar,
  },
  data() {
    return {
      terms: [
        {
          title: "1. 服务内容",
          content: "本平台提供社区交流服务，包括但不限于租房信息发布与浏览、招聘求职信息交流、二手物品交易信息发布等。用户可以发布、浏览相关信息"
        },
        {
          title: "2. 用户资格",
          content: "用户必须年满18周岁，具有完全民事行为能力。注册时应提供真实、准确、完整的个人信息。用户应对账号安全负责，不得将账号转让或出借给他人使用。"
        },
        {
          title: "3. 发布规则",
          content: "3.1 租房信息发布：用户发布的房源信息必须真实有效，不得虚假宣传，需注明房屋具体位置、价格、面积等关键信息。\n" +
              "3.2 招聘求职信息：应明确标注工作岗位、薪资范围、工作地点、任职要求等信息，不得发布虚假招聘信息或变相收费。\n" +
              "3.3 二手交易信息：商品描述要客观真实，标明价格及商品状况，不得销售国家禁止交易的物品。"
        },
        {
          title: "4. 禁止行为",
          content: "用户不得发布以下内容：\n" +
              "- 违反国家法律法规的信息\n" +
              "- 虚假或具有欺骗性的信息\n" +
              "- 侵犯他人知识产权的内容\n" +
              "- 骚扰、诽谤、威胁他人的言论\n" +
              "- 含有色情、暴力、赌博等违法内容\n" +
              "- 发布垃圾广告或恶意营销信息"
        },
        {
          title: "5. 信息审核",
          content: "平台有权对用户发布的信息进行审核，对违规内容进行删除或隐藏。如发现违规行为，可能采取警告、限制功能使用、封禁账号等处理措施。"
        },
        {
          title: "6. 安全与隐私",
          content: "6.1 平台将采取合理措施保护用户个人信息安全。\n" +
              "6.2 用户之间的私下交易行为产生的风险由用户自行承担。\n" +
              "6.3 建议用户在进行租房、招聘等活动时注意核实对方身份，保护个人财产安全。"
        },
        {
          title: "7. 知识产权",
          content: "用户发布的内容所有权归用户所有，但用户授予平台在全球范围内免费、非独家、可转授权的许可使用权。平台有权对这些内容进行展示、推广和传播。"
        },
        {
          title: "8. 免责声明",
          content: "8.1 平台仅提供信息发布渠道，不对用户发布信息的真实性、准确性承担责任。\n" +
              "8.2 用户间交易产生的纠纷由双方自行解决，平台不承担责任。\n" +
              "8.3 因不可抗力导致的服务中断，平台不承担责任。"
        },
        {
          title: "9. 服务变更与终止",
          content: "平台保留随时修改或终止服务的权利。如用户违反本协议，平台有权限制或终止其使用服务的权利。"
        },
        {
          title: "10. 争议解决",
          content: "本协议适用中华人民共和国法律。如产生纠纷，双方应友好协商；协商不成的，任何一方均可向平台运营者所在地人民法院提起诉讼。"
        }
      ]
    };
  }
};
</script>

<style scoped>
.terms-page {
  padding: 16px;
  background-color: #f5f5f5;
}

.terms-content .custom-cell {
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
}

.cell-title {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 18px;
  color: #333;
  text-align: left;
}

.content {
  color: #666;
  font-size: 15px;
  line-height: 1.8;
  text-align: left;
  white-space: pre-line; /* 保留换行符 */
}

:deep(.van-cell) {
  padding: 0;
  background-color: transparent;
}
</style>
