// src/utils/apiResponseHandler.js

import {showToast} from 'vant';
import router from "@/router";

export const handleApiResponse = (response, router,basePath) => {
    if (response && response.data) {
        if (response.data.code === 0) {
            console.error("回参"+response.data.code)
            showSuccessToast(response.data.message, router,basePath, response.data.data);
        } else if (response.data.code === 1) {
            showFailToast(response.data.message);
        }
    } else {
        showToast('操作成功，但未收到服务器响应数据');
    }
};

export const handUpdateApiResponse = (response, router,basePath) => {
    if (response && response.data) {
        if (response.data.code === 0) {
            console.error("回参"+response.data.code)
            showUpdateSuccessToast (response.data.message);
        } else if (response.data.code === 1) {
            showFailToast(response.data.message);
        }
    } else {
        showToast('操作成功，但未收到服务器响应数据');
    }
};


export const showUpdateSuccessToast = (message) => {
    showToast({
        type: 'success',
        message: message,
    });
};

export const showSuccessToast = (message, router,basePath,postId) => {
    showToast({
        type: 'success',
        message: message,
        onClose: () => {
            router.push({
                name: 'PostSuccess',
                params: {
                    basePath: basePath,
                    postId: postId,
                },
                query: {
                    mode: 'view',
                },
            });

        }
    });
};

export const showFailToast = (message) => {
    showToast({
        type: 'fail',
        message: message,
    });
};

export const handleApiError = (error) => {
    console.error('API 调用出错:', error);
    if (error.response && error.response.data) {
        showFailToast('操作失败：' + error.response.data);
    } else {
        showFailToast('操作失败，出现未预期的错误。');
    }
};