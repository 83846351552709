// index.js
import { createStore } from 'vuex';

export const index = createStore({
  state() {
    return {
      basePath: '',  // 用于存储 basePath 字符串
      postId: null   // 用于存储 post.id
    };
  },
  mutations: {
    setBasePath(state, basePath) {
      state.basePath = basePath;  // 更新 basePath
    },
    setPostId(state, postId) {
      state.postId = postId;  // 更新 post.id
    }
  }
});
