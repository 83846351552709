<template>
  <van-field name="salary" label="预期工资" :error-message="localErrorMessage">
    <template #input>
      <div class="salary-input-container">
        <input
            style="width: 65px"
            v-model="minSalary"
            type="number"
            placeholder="最低"
            class="salary-input"
            @blur="validateSalary"
            @input="updateMin"
            :readonly="readonly"
        />
        <span class="salary-separator">-</span>
        <input
            style="width: 65px"
            v-model="maxSalary"
            type="number"
            placeholder="最高"
            class="salary-input"
            @blur="validateSalary"
            @input="updateMax"
            :readonly="readonly"
        />
        <span class="salary-unit">元/月</span>
      </div>
    </template>
  </van-field>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  name: 'SalaryInput',
  props: {
    min: {
      type: [Number, String],
      default: ''
    },
    max: {
      type: [Number, String],
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    readonly: {  // 接收 readonly 属性
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 根据模式判断是否为只读
    isReadOnly() {
      return this.mode === 'view';
    },
  },
  emits: ['update:min', 'update:max', 'validate'],
  setup(props, { emit }) {
    const minSalary = ref(props.min);
    const maxSalary = ref(props.max);
    const localErrorMessage = ref(props.errorMessage);

    watch(() => props.min, (newVal) => {
      minSalary.value = newVal;
    });

    watch(() => props.max, (newVal) => {
      maxSalary.value = newVal;
    });

    watch(() => props.errorMessage, (newVal) => {
      localErrorMessage.value = newVal;
    });

    const updateMin = () => {
      emit('update:min', minSalary.value);
      validateSalary();
    };

    const updateMax = () => {
      emit('update:max', maxSalary.value);
      validateSalary();
    };

    const validateSalary = () => {
      if (minSalary.value && !maxSalary.value) {
        localErrorMessage.value = '请填写最高工资';
        emit('validate', false);
      } else if (!minSalary.value && maxSalary.value) {
        localErrorMessage.value = '请填写最低工资';
        emit('validate', false);
      } else if (minSalary.value && maxSalary.value) {
        if (Number(minSalary.value) > Number(maxSalary.value)) {
          localErrorMessage.value = '最低工资不能高于最高工资';
          emit('validate', false);
        } else {
          localErrorMessage.value = '';
          emit('validate', true);
        }
      } else {
        localErrorMessage.value = '';
        emit('validate', true);
      }
    };

    return {
      minSalary,
      maxSalary,
      localErrorMessage,
      updateMin,
      updateMax,
      validateSalary
    };
  }
}
</script>

<style scoped>
.salary-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.salary-input {
  width: 80px;
  height: 30px;
  border: 1px solid #dcdee0;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
  color: #323233;
}

.salary-input:focus {
  border-color: #1989fa;
  outline: none;
}

.salary-separator {
  margin: 0 8px;
  color: #969799;
}

.salary-unit {
  margin-left: 2px;
  color: #969799;
  font-size: 14px;
}
</style>