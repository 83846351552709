<template>
  <div class="First_div">
    <!--    <SearchBar/>-->
    <Swipe/>
    <MainView/>
    <DateList/>
  </div>


</template>

<script>
import IconList from "@/components/home/item/IconList.vue";
import SearchBar from "@/components/home/SearchBar";
import Weather from "@/components/home/item/Weather";
import Swipe from "@/components/home/item/Swipe";
import DateList from "@/DataView/DataList.vue";
import MainView  from "@/DataView/MainView.vue";


export default {
  components: {
    MainView,
    Swipe,
    DateList,

  },
};
</script>


<style lang="less" scoped>
.First_div {
  padding-bottom: 10vh;
}

</style>
