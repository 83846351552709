<template>
  <FooterList/>

  <!-- 仅在指定路径时显示 FooterList2 -->
  <FooterList2 v-if="shouldShowFooterList2"/>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router'; // 引入 vue-router 的 useRoute 钩子
import FooterList from "@/components/home/item/FooterList";
import FooterList2 from "@/components/home/item/FooterList2";

export default {
  components: { FooterList2, FooterList },

  setup() {
    const route = useRoute(); // 获取当前路由

    // 通过计算属性判断是否应该显示 FooterList2
    const shouldShowFooterList2 = computed(() => {
      const validPaths = ['/first', '/subscribe', '/contactMe', '/joinChat'];
      return validPaths.includes(route.path); // 如果当前路由路径在列表中，则返回 true
    });

    return {
      shouldShowFooterList2
    };
  }
};
</script>
