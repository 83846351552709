<template>
  <div class="field-wrapper">
    <div class="field-label">上传图片</div>
    <van-uploader
        v-model="internalFileList"
        :before-read="beforeRead"
        multiple
        :max-count="5"
        accept="image/*"
    />
    <div class="upload-tip">最多上传5张图片，将自动压缩至1MB以下</div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import {compressAndWatermarkImage} from '@/js/imageUtils';
import {showToast} from 'vant';

export default {
  emits: ['update:files'],
  setup(props, {emit}) {
    const internalFileList = ref([]);
    const processingFiles = ref(new Set());

    const beforeRead = (file) => {
      return new Promise((resolve, reject) => {
        if (!(file instanceof File)) {
          showToast('无效的文件对象');
          reject(new Error('Invalid file object'));
          return;
        }

        processingFiles.value.add(file.name);

        compressAndWatermarkImage(file)
            .then(processedFile => {
              const newFile = {
                url: URL.createObjectURL(processedFile),
                file: processedFile,
                name: processedFile.name
              };
              internalFileList.value.push(newFile);
              emitUpdatedFiles();
              resolve(newFile);
            })
            .catch(err => {
              console.error('Error processing image:', err);
              showToast('处理图片失败');
              reject(err);
            })
            .finally(() => {
              processingFiles.value.delete(file.name);
            });
      });
    };

    const emitUpdatedFiles = () => {
      const files = internalFileList.value
          .filter(f => f.file instanceof File)
          .map(f => f.file);
      emit('update:files', files);
    };

    watch(internalFileList, emitUpdatedFiles, {deep: true});

    return {
      internalFileList,
      beforeRead
    };
  }
};
</script>

<style scoped>
.upload-tip {
  font-size: 14px;
  color: #969799;
  margin-top: 8px;
}

.field-wrapper {
  background-color: #fff;
  padding: 16px;
  margin-left: 50px;
  border-radius: 8px;
}

.field-label {
  font-size: 16px;
  margin-bottom: 8px;
}
</style>