<template>
  <div class="job-seek-form">
    <van-form @submit="submitPost">
      <van-cell-group inset>
        <van-field
            v-model="formData.message"
            rows="3"
            autosize
            type="textarea"
            label="求职信息"
            placeholder="请描述您的求职意向"
            maxlength="300"
            show-word-limit
            required
            clearable
        />


        <van-field
            v-model="formData.nickname"
            required
            label="姓名"
            placeholder="请输入姓名"
        />
        <van-field
            v-model="formData.tel"
            type="tel"
            label="联系电话"
            placeholder="请输入手机号"
            required
        />

        <van-collapse v-model="activeNames">
          <van-collapse-item title="填写更多信息，更容易被老板看中" name="0">

            <van-field
                readonly
                clickable
                name="gender"
                label="性别"
                :value="formData.gender ? '男' : '女'"
                placeholder="请选择性别"
                @click="showGenderPicker = true"
            />
            <van-popup v-model:show="showGenderPicker" round position="bottom">
              <van-picker
                  :columns="genderOptions"
                  @cancel="showGenderPicker = false"
              />
            </van-popup>

<!--            <van-field-->
<!--                readonly-->
<!--                clickable-->
<!--                name="dateOfBirth"-->
<!--                label="出生日期"-->
<!--                :value="formData.dateOfBirth"-->
<!--                placeholder="点击选择出生日期"-->
<!--                @click="showDatePicker = true"-->
<!--            />-->
            <van-popup v-model:show="showDatePicker" position="bottom">
              <van-date-picker
                  @cancel="showDatePicker = false"
                  :min-date="new Date(1950, 0, 1)"
                  :max-date="new Date()"
              />
            </van-popup>


            <van-field
                v-model.number="formData.desiredSalary"
                type="digit"
                name="desiredSalary"
                label="期望薪资"
                placeholder="请输入期望薪资（元/月）"
            />

            <van-field
                readonly
                clickable
                name="availableStartDate"
                label="可入职日期"
                :value="formData.availableStartDate"
                placeholder="点击选择可入职日期"
                @click="showAvailableDatePicker = true"
            />
            <van-popup v-model:show="showAvailableDatePicker" position="bottom">
              <van-date-picker

                  @cancel="showAvailableDatePicker = false"
                  :min-date="new Date()"
              />
            </van-popup>

            <van-field
                v-model="formData.locationPreference"
                name="locationPreference"
                label="期望工作地点"
                placeholder="请输入期望的工作地点"
            />

            <van-field
                v-model="formData.educationBackground"
                rows="2"
                autosize
                label="教育背景"
                type="textarea"
                maxlength="500"
                placeholder="请描述您的教育背景"
                show-word-limit
            />

            <van-field
                v-model="formData.workExperience"
                rows="2"
                autosize
                label="工作经验"
                type="textarea"
                maxlength="1000"
                placeholder="请描述您的工作经验"
                show-word-limit
            />

            <van-field
                v-model="formData.skills"
                rows="2"
                autosize
                label="技能"
                type="textarea"
                maxlength="500"
                placeholder="请列出您的技能"
                show-word-limit
            />

            <van-field name="showPersonalResume" label="展示个人简历">
              <template #input>
                <van-switch v-model="formData.showPersonalResume" size="20"/>
              </template>
            </van-field>

            <ImageUploader @update:files="updateFiles"/>
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交求职信息
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {findTagsByName} from "@/js/postTags";

import { ref, reactive } from 'vue';
import {showToast} from 'vant';
import {useRouter} from 'vue-router';
import {handleApiResponse, handleApiError} from '@/js/apiResponseHandler';
import {sendPost} from '@/request/api/home';
import ImageUploader from "@/components/post/item/ImageUploader";
import TagSelector from "@/components/post/item/TagSelector";


export default {
  components: {
    ImageUploader,
    TagSelector,
  },
  props: {
    basePath: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const showDatePicker = ref(false);
    const showAvailableDatePicker = ref(false);
    const showGenderPicker = ref(false);
    const activeNames = ref(['1']);
    const genderOptions = [
      {text: '男', value: true},
      {text: '女', value: false}
    ];
    const tags = ref(findTagsByName('jobs-seek'));
    const selectedTags = () => tags.value.filter(tag => tag.selected).map(tag => tag.name);
    const formData = reactive({
      nickname: 'sb',
      userId: 0, // 这个可能需要从用户会话中获取
      message: 'xx',
      tel: '18375659564',
      // dateOfBirth: '',
      // gender: null,
      // tags: '',
      // desiredSalary: '',
      // availableStartDate: '',
      // locationPreference: '',
      // educationBackground: '',
      // workExperience: '',
      // skills: '',
      // showPersonalResume: false,
      images: []
    });
    const files = ref([]); // 使用 ref 来存储文件列表

    const toggleTag = (index) => {
      tags.value[index].selected = !tags.value[index].selected;
    };
    const validatePhone = (value) => {
      const pattern = /^1[3-9]\d{9}$/;
      return pattern.test(value);
    };

    const validateForm = () => {
      if (!formData.message.trim()) {
        showToast('请填写职位描述');
        return false;
      }
      if (!formData.nickname.trim()) {
        showToast('请填写联系人姓名');
        return false;
      }
      if (!validatePhone(formData.tel)) {
        showToast('请输入有效的手机号');
        return false;
      }
      return true;
    };

    const updateFiles = (updatedFiles) => {
      files.value = updatedFiles;
      console.log('Updated files:', files.value);
    };

    const submitPost = async () => {
      console.log('开始提交表单');
      console.log('formData:', formData);

      if (!validateForm()) return;

      const submitData = new FormData();
      console.log('创建了新的 FormData 对象');

      // 添加非文件字段
      Object.entries(formData).forEach(([key, value]) => {
        submitData.append(key, value);
        console.log(`添加字段: ${key} = ${value}`);
      });

      // 添加标签
      submitData.append('tags', selectedTags().join(','));

      // 处理文件上传
      console.log('开始处理文件列表');
      console.log('files:', files.value);

      files.value.forEach((file, index) => {
        if (file instanceof File) {
          submitData.append('images', file, file.name);
          console.log(`添加 File 对象: ${file.name}`);
        } else {
          console.error('无效的文件对象:', file);
        }
      });

      console.log(`总共处理了 ${files.value.length} 个文件`);

      // 检查 FormData 中的内容
      for (let [key, value] of submitData.entries()) {
        console.log(key, value);
      }

      try {
        const response = await sendPost(props.basePath, submitData);
        handleApiResponse(response, router);
      } catch (error) {
        handleApiError(error);
      }

    };


    return {
      submitPost,
      updateFiles,
      tags,
      toggleTag,
      formData,
      showDatePicker,
      showAvailableDatePicker,
      showGenderPicker,
      genderOptions,
      activeNames,
    };
  }
};
</script>

<style scoped>
.job-seek-form {
  padding: 16px;
}

.upload-wrapper {
  padding: 16px;
}

:deep(.van-cell-group) {
  background-color: #ffffff;
}

:deep(.van-field__label) {
  width: 90px;
  flex: none;
}

:deep(.van-cell:not(:last-child)::after) {
  left: 16px;
  right: 16px;
}

:deep(.van-collapse-item__title) {
  color: #4682ff;
  font-size: 14px;
}
</style>