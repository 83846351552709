<template>
  <div class="login-container">
    <h2>登录</h2>
    <van-field
        v-model="username"
        label="用户名"
        placeholder="请输入用户名"
        required
    />
    <van-field
        v-model="password"
        label="密码"
        type="password"
        placeholder="请输入密码"
        required
    />
    <van-button type="primary" @click="handleLogin">登录</van-button>
  </div>
</template>

<script>
import { showDialog } from 'vant';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    handleLogin() {
      // 检查是否填写完整
      if (!this.username || !this.password) {
        showDialog({
          message: '请填写完整信息',
        });
        return;
      }

      // 假设用户未注册，显示提示对话框
      showDialog({
        title: '提示',
        message: '该账户未注册，请先完成注册',
      })
          .then(() => {
            // 用户点击确认按钮后的处理
            this.$router.push('/register'); // 跳转到注册页面
          })
          .catch(() => {
            // 用户点击取消按钮后的处理
            // 这里可以不做任何处理
          });
    }
  }
};
</script>

<style scoped>
.login-container {
  padding: 20px;
  max-width: 400px;
  margin: auto;
}
</style>
