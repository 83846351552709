<template>
  <div>
    <!-- 帖子列表容器 -->
    <div ref="postList" class="post-list">
        <PostItemCard
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
            :basePath="basePath"
        />
    </div>


    <!-- 分页控件 -->
    <van-pagination
        v-model="pageNumber"
        :total-items="totalPosts"
        :items-per-page="pageSize"
        @change="onPageChange"
        prev-text="上一页"
        next-text="下一页"
        class="custom-pagination"
    />
    <van-back-top  right="8vw" bottom="24vh"/>
  </div>
</template>

<script>
import {ref, onMounted, watch} from 'vue';
import PostItemCard from '@/components/post/item/PostItemCard';
import {getPostList} from '@/request/api/home';  // 数据获取方法
export default {
  components: {
    PostItemCard,
  },
  props: {
    basePath: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const posts = ref([]);
    const totalPosts = ref(0);
    const pageNumber = ref(1);
    const pageSize = ref(10);  // 每页显示的帖子数量

    // 数据获取方法
    const fetchData = () => {
      console.error("请求数据："+props.basePath+"页数："+pageNumber.value)
      getPostList(props.basePath, pageNumber.value, pageSize.value)
          .then(response => {
            if (response && response.data.code === 0) {
              posts.value = response.data.data;
              totalPosts.value = response.data.count;
            } else {
              console.error("请求失败:", response.data.message || "未知错误");
            }
          })
          .catch(error => {
            console.error("加载数据失败，发生未知错误");
          });
    };

    // 页码变化时触发数据重新加载
    const onPageChange = (newPage) => {
      pageNumber.value = newPage;
      fetchData();
      window.scrollTo({top: 0, behavior: 'smooth'});
    };

    // 初始化时加载数据
    onMounted(fetchData);

    // 监听 basePath 变化时重新加载数据
    watch(() => props.basePath, fetchData);

    return {
      posts,
      totalPosts,
      pageNumber,
      pageSize,
      onPageChange,
      fetchData,
    };
  },
};
</script>

<style scoped>
.post-list {
  /* 这里可以根据你的需求自定义 */
  background-color: #e5e5e5;
}
/* 使用 /deep/ 选择器覆盖 van-pagination 样式 */
.custom-pagination /deep/
.van-pagination {
  height: 60px; /* 调整高度 */
  font-size: 14px; /* 调整字体大小 */
}

/* 具体页码按钮的样式 */
.custom-pagination /deep/ .van-pagination__item {
  height: 36px;
  line-height: 36px;
  font-size: 14px; /* 调整页码字体大小 */
}

/* 调整上一页、下一页按钮的样式 */
.custom-pagination /deep/ .van-pagination__prev,
.custom-pagination /deep/ .van-pagination__next {
  font-size: 14px; /* 调整按钮字体大小 */
}
</style>
