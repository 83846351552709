<template>
  <div class="field-wrapper">
    <div v-if="mode === 'edit'" class="field-label">福利标签</div>
    <div class="tags-container">
      <van-tag
          v-for="(tag, index) in tags"
          :key="index"
          :class="['tag', { 'tag-selected': tag.selected }]"
          plain
          @click.stop="handleTagClick(index)"
          :style="{ cursor: mode === 'view' ? 'default' : 'pointer' }"
      >
        {{ tag.name }}
      </van-tag>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'TagSelector',
  props: {
    tags: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      default: 'edit',
      validator: (value) => ['edit', 'create', 'view'].includes(value)
    },
    selectedTags: {
      type: String,
      default: ''
    }
  },
  emits: ['update:selectedTags'],
  setup(props, { emit }) {
    const handleTagClick = (index) => {
      if (props.mode !== 'view') {
        const updatedTags = props.tags.map((tag, i) =>
            i === index ? { ...tag, selected: !tag.selected } : tag
        );

        const selectedTagNames = updatedTags
            .filter(tag => tag.selected)
            .map(tag => tag.name)
            .join(',');

        emit('update:selectedTags', selectedTagNames);
      }
    };

    return {
      handleTagClick
    };
  }
};
</script>

<style scoped>
.field-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 3px;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  color: #323233;
  user-select: none;
}

.tag:hover:not(.view-mode) {
  border-color: #1989fa;
  color: #1989fa;
}

.tag-selected {
  background-color: #e6f7ff;
  border-color: #1989fa;
  color: #1989fa;
}

.tag-selected:hover:not(.view-mode) {
  background-color: #d6f0ff;
}
</style>