<template>
  <NavigationBar title="浏览足迹"/>
  <div class="guide-container">
    <div class="content">
      <!-- 图标或图片 -->
      <text style="font-size: 40px">
        请先登录或者注册账户
      </text>

      <!-- 按钮组 -->
      <div class="button-group">
        <van-button
            type="primary"
            round
            block
            @click="goToRegister"
            class="register-btn"
        >
          注册账户
        </van-button>

        <van-button
            type="default"
            round
            block
            @click="goToLogin"
            class="login-btn"
        >
          立即登录
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/views/NavigationBar.vue";

export default {
  name: 'LoginGuide',
  components: {NavigationBar},
  methods: {
    goToRegister() {
      this.$router.push('/register');
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.guide-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
}

.content {
  text-align: center;
}

.button-group {
  margin-top: 40px;
  padding: 0 20px;
}

.register-btn {
  margin-bottom: 15px;
}

.login-btn {
  border: 1px solid #1989fa;
  color: #1989fa;
}

/* 自定义空状态组件的样式 */
:deep(.van-empty) {
  padding: 40px 0;
}

:deep(.van-empty__description) {
  font-size: 16px;
  color: #666;
  margin-top: 20px;
}
</style>
