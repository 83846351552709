<template>
  <van-tabbar class="tabbar-container" v-model="activeTab" route>
    <van-tabbar-item icon="home-o" :to="{ path: '/first' }">首页</van-tabbar-item>
    <van-tabbar-item icon="chat-o" :to="{ path: '/messages' }">通知</van-tabbar-item>
    <van-tabbar-item icon="plus" :to="{ path: '/selectPublishItem' }">发布</van-tabbar-item>
    <van-tabbar-item icon="star-o" :to="{ path: '/favorites' }">收藏</van-tabbar-item>
    <van-tabbar-item icon="manager-o" :to="{ path: '/personalInfo' }">个人</van-tabbar-item>
  </van-tabbar>

</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const activeTab = ref(0); // 将 'active' 重命名为更有意义的 'activeTab'
    return { activeTab };
  }
};
</script>

<style lang="less" scoped>
/* Tabbar 始终固定在页面底部 */
.tabbar-container {
  position: fixed;
  bottom: 35px;
  left: 0;
  width: 100%;
  height: 4.5vh;
  opacity: 0.9;
  background-color: #fff; /* 确保背景色与内容分开 */
  z-index: 999; /* 提高 z-index 确保其在输入法弹出时仍然可见 */
}
</style>
