<template>
  <div class="post-form">
    <van-form @submit="submitPost">
      <van-cell-group inset>
        <van-field
            v-model="formData.message"
            rows="4"
            autosize
            type="textarea"
            label="职位描述"
            placeholder="描述公司介绍，招聘岗位，工作地点、工作时间、假期,福利待遇，工资发放情况等"
            maxlength="300"
            show-word-limit
            required
            clearable
        />

        <TagSelector :tags="tags" @toggle-tag="toggleTag" />

        <van-field
            v-model="formData.location"
            label="工作地点"
            placeholder="例：重庆市江津区xxx"
            required
        />

        <SalaryInput
            v-model:min="formData.salaryMin"
            v-model:max="formData.salaryMax"
            :error-message="salaryError"
            @validate="validateSalary"
        />

        <van-field
            v-model="formData.nickname"
            label="联系人"
            placeholder="请输入联系人姓名"
            required
        />

        <van-field
            v-model="formData.tel"
            type="tel"
            label="联系电话"
            placeholder="请输入手机号"
            required
        />

        <ImageUploader @update:files="updateFiles" />
      </van-cell-group>

      <div class="submit-button-wrapper">
        <van-button round block type="primary" native-type="submit">
          发布招聘信息
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { showToast } from 'vant';
import { sendPost } from '@/request/api/home';
import { findTagsByName } from "@/js/postTags";
import ImageUploader from '@/components/post/item/ImageUploader.vue';
import TagSelector from "@/components/post/item/TagSelector.vue";
import SalaryInput from "@/components/post/item/SalaryInput.vue";
import { useRouter } from 'vue-router';
import { handleApiResponse, handleApiError } from '@/js/apiResponseHandler';

export default {
  components: {
    ImageUploader,
    TagSelector,
    SalaryInput
  },
  props: {
    basePath: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const formData = reactive({
      message: 'xxx',
      salaryMin: '12',
      salaryMax: '32',
      location: 'location',
      nickname: 'sb',
      tel: '18375659564',
    });

    const tags = ref(findTagsByName('jobs-hire'));
    const salaryError = ref('');
    const isSalaryValid = ref(true);
    const files = ref([]); // 使用 ref 来存储文件列表

    const selectedTags = () => tags.value.filter(tag => tag.selected).map(tag => tag.name);

    const toggleTag = (index) => {
      tags.value[index].selected = !tags.value[index].selected;
    };

    const validateSalary = (isValid) => {
      isSalaryValid.value = isValid;
    };

    const validatePhone = (value) => {
      const pattern = /^1[3-9]\d{9}$/;
      return pattern.test(value);
    };

    const validateForm = () => {
      if (!formData.message.trim()) {
        showToast('请填写职位描述');
        return false;
      }
      if (!isSalaryValid.value) {
        showToast(salaryError.value || '薪资范围输入有误');
        return false;
      }
      if (!formData.location.trim()) {
        showToast('请填写工作地点');
        return false;
      }
      if (!formData.nickname.trim()) {
        showToast('请填写联系人姓名');
        return false;
      }
      if (!validatePhone(formData.tel)) {
        showToast('请输入有效的手机号');
        return false;
      }
      return true;
    };

    const updateFiles = (updatedFiles) => {
      files.value = updatedFiles;
      console.log('Updated files:', files.value);
    };


    const submitPost = async () => {
      console.log('开始提交表单');
      console.log('formData:', formData);

      if (!validateForm()) return;

      const submitData = new FormData();
      console.log('创建了新的 FormData 对象');

      // 添加非文件字段
      Object.entries(formData).forEach(([key, value]) => {
        submitData.append(key, value);
        console.log(`添加字段: ${key} = ${value}`);
      });

      // 添加标签
      submitData.append('tags', selectedTags().join(','));

      // 处理文件上传
      console.log('开始处理文件列表');
      console.log('files:', files.value);

      files.value.forEach((file, index) => {
        if (file instanceof File) {
          submitData.append('images', file, file.name);
          console.log(`添加 File 对象: ${file.name}`);
        } else {
          console.error('无效的文件对象:', file);
        }
      });

      console.log(`总共处理了 ${files.value.length} 个文件`);

      // 检查 FormData 中的内容
      for (let [key, value] of submitData.entries()) {
        console.log(key, value);
      }

      try {
        const response = await sendPost(props.basePath, submitData);
        handleApiResponse(response, router);
      } catch (error) {
        handleApiError(error);
      }

    };


    return {
      formData,
      tags,
      salaryError,
      toggleTag,
      validateSalary,
      submitPost,
      updateFiles
    };
  }
};
</script>

<style scoped>
.submit-button-wrapper {
  margin-top: 24px;
  width: 100%;
}
</style>