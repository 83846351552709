import service from "..";


export function getTest() {
    return service({
        method: "GET",
        url: `/test`
    })
}

export function getWeather() {
    return service({
        method: "GET",
        url: `/get-latest-weather`
    })
}

export function sendEmail(email) {
    return service({
        method: "POST",
        url: "/email/send",
        params: {  // 使用 params 来发送 请求的查询参数
            email: email
        }
    })
}

export function verifyEmailCode(email, code) {
    return service({
        method: "POST",
        url: "/email/verify",  // 这里填写你的完整后端 URL 路径
        params: {  // 使用 params 来发送 请求的查询参数
            email: email,
            code: code
        }
    });
}


export function sendPost(basePath, formData) {
    return service({
        method: "POST",
        url: `post/${basePath}/post`,  // 动态生成请求的 basePath
        data: formData,  // 使用 formData 发送多部分数据
        headers: { 'Content-Type': 'multipart/form-data' }  // 设置请求头
    });
}


// 获取帖子列表的请求函数
export function getPostList(basePath,pageNumber,pageSize) {
    return service({
        method: "GET",
        url: `/post/${basePath}/list`,  // 动态根据 basePath 构建 URL
        params: {
            pageNumber: pageNumber,    // 当前页码
            pageSize: pageSize    // 每页条数
        }
    });
}

// 更新帖子内容的请求函数
export function updatePost(basePath, postId, postData) {
    return service({
        method: "PUT",
        url: `/post/${basePath}/${postId}`,  // 动态生成 URL，传递 basePath 和 postId
        data: postData,  // 使用 postData 发送需要更新的帖子内容数据
        headers: { 'Content-Type': 'multipart/form-data' }  // 设置请求头

    });
}


// 根据 basePath 和 postId 查询单个帖子详情的请求函数
export function getPostById(basePath, postId) {
    return service({
        method: "GET",
        url: `/post/${basePath}/${postId}`,  // 动态根据 basePath 和 postId 构建 URL
    });
}



export function getAnnouncementTest() {
    return service({
        method: "GET",
        url: `/api/announcement/current`
    })
}