<template>


  <router-view/>
  <FooterNav/>
</template>

<script>
import First from "@/components/home/First";
import FooterNav from "@/components/home/item/FooterNav";

export default {
  components: {
    First,
    FooterNav,
  },
};
</script>

<style lang="less" scoped>

</style>
