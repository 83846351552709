<template>
  <div class="swiper-container">
    <van-swipe :autoplay="3000" indicator-color="white">
      <!-- 第一个轮播项 -->
      <van-swipe-item>
        <Weather/>
      </van-swipe-item>

      <!-- 第二个轮播项 -->
      <van-swipe-item>
        <div class="swiper-image"></div>
      </van-swipe-item>

      <!-- 第三个轮播项 -->
      <van-swipe-item>
        <div class="custom-content">
          <h2>第三项内容</h2>
          <p>你可以放任何组件或内容在这里</p>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant';
import Weather from "@/components/home/item/Weather";

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Weather,
  },
  methods: {
    handleClick() {
      alert('你点击了按钮！');
    }
  }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  position: relative;
  height: auto; /* 根据内容自动调整高度 */
  height: 20vh;
}

.custom-content, .swiper-image {
  height: auto; /* 内容决定高度 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-image {

  margin: 3% auto 0;
  width: 96%;
  border-radius: 15px;
  background-color: #ffffff;
  background-image: url('~@/assets/banner_便民信息.png');
  background-size: contain; /* 图片保持比例，缩放至适应宽度或高度 */
  background-position: center; /* 背景图片居中显示 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
}

.custom-content {

  background-color: #d72626;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.custom-content h2 {
  font-size: 2rem;
}

.custom-content p {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #333;
}
</style>
