<template>
  <!-- Tabbar 导航栏 -->
  <van-tabbar class="tabbar-container" route>
    <van-tabbar-item :to="{ path: '/subscribe' }">
      <template #icon>
        <img src="@/assets/footerlist/订阅信息.svg" />
      </template>
      订阅信息
    </van-tabbar-item>
    <van-tabbar-item :to="{ path: '/contactMe' }">
      <template #icon>
        <img src="@/assets/footerlist/在线客服.svg" />
      </template>
      在线客服
    </van-tabbar-item>
    <van-tabbar-item :to="{ path: '/UserServiceTerms' }">
      <template #icon>
        <img src="@/assets/PersonalInfoPage/用户须知.svg" />
      </template>
      用户须知
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
/* Tabbar 容器的样式 */
.tabbar-container {
  position: fixed; /* 绝对固定 */
  bottom: 7.2%; /* 始终固定在底部 */
  left: 0;
  width: 100%;
  max-height: 6vh;
  background-color: transparent; /* 设置背景透明 */
  z-index: 999; /* 提高优先级，确保不被其他元素覆盖 */
}

/* 保持 Tabbar 选中时背景透明 */
.van-tabbar-item--active {
  background-color: transparent !important; /* 选中时透明背景 */
}

/* 覆盖点击时的背景颜色 */
.van-tabbar-item {
  background-color: transparent !important; /* 默认透明背景 */
}

.van-tabbar-item:active {
  background-color: transparent !important; /* 点击时背景保持透明 */
}
</style>
