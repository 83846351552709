import axios from 'axios';

let service = axios.create({
    baseURL: "/api",
    timeout: 3000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

export default service