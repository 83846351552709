<template>
  <NavigationBar :title="pageTitle"/>
  <!-- 根据 basePath 动态选择表单组件 -->

  <component :is="selectedComponent" :basePath="basePath"/>

</template>

<script>
import JobHirePostForm from '@/components/views/jobs/JobHirePostForm.vue';
import JobSeekPostForm from '@/components/views/jobs/JobSeekPostForm.vue'; // 假设求职表单的路径
import RentalOfferPostForm from '@/components/views/rentals/RentalOfferPostForm.vue'; // 假设租房表单的路径
import NavigationBar from '@/components/views/NavigationBar.vue';
import DefaultForm from '@/components/views/DefaultForm.vue';

export default {
  props: ['basePath', 'name'],
  components: {
    JobHirePostForm,
    JobSeekPostForm,
    RentalOfferPostForm,
    NavigationBar,
    DefaultForm,
  },
  computed: {
    selectedComponent() {
      // 根据 basePath 返回不同的组件
      console.error(this.basePath);
      switch (this.basePath) {
        case 'jobs-hire':
          return 'JobHirePostForm'; // 招聘表单
        case 'jobs-seek':
          return 'JobSeekPostForm'; // 求职
        case 'rentals-offer':
          return 'RentalOfferPostForm'; // 租房表单
        default:
          return 'DefaultForm'; // 默认表单
      }
    },
    pageTitle() {
      // 使用 name 动态修改标题
      return `发布${this.name}信息`;
    }
  },
};
</script>

