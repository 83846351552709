export const postCategories = [
    {
        id: 0,
        name: '招聘求职',
        basePath: 'jobs',
        icon: require("@/assets/postlist/找工作.svg"),
        stats: { posts: 34, subscribers: 72 },
        subcategories: [
            { name: '招聘', basePath: 'jobs-hire' },
            { name: '求职', basePath: 'jobs-seek' },
        ]
    },
    {
        id: 1,
        name: '租房',
        basePath: '/rentals',
        icon: require("@/assets/postlist/房子.svg"),
        description: '出租 租房 买房 卖房',
        subcategories: [
            { name: '我要租房', basePath: 'rentals-seek' },
            { name: '我要出租', basePath: 'rentals-offer' },
            { name: '我要买房', basePath: 'house-buy' },
            { name: '我要卖房', basePath: 'house-sell' }
        ]
    },
    { id: 2, name: '生意转让', basePath: 'business-transfer', icon: require("@/assets/postlist/生意转让.svg"), description: '生意转让 门面出售 发现好生意' },
    { id: 3, name: '二手物品', basePath: 'second-hand-items', icon: require("@/assets/postlist/二手物品.svg"), description: '本地跳蚤市场' },
    { id: 4, name: '宠物交易', basePath: 'pet-trading', icon: require("@/assets/postlist/宠物.svg"), description: '宠物交流圈子' },
    { id: 5, name: '吃喝玩乐', basePath: 'leisure-entertainment', icon: require("@/assets/postlist/吃喝玩乐.svg"), description: '同步微信资料xxx' },
    { id: 6, name: '母婴圈', basePath: 'parenting-circle', icon: require("@/assets/postlist/母婴.svg"), description: '同步微信资料xxx' },
    { id: 7, name: '杂谈', basePath: 'local-talk', icon: require("@/assets/postlist/找工作.svg"), description: '同步微信资料xxx' },
    { id: 8, name: '本地服务', basePath: 'local-services', icon: require("@/assets/postlist/本地服务.svg"), description: '同步微信资料xxx' },
    { id: 9, name: '找家政', basePath: 'housekeeping', icon: require("@/assets/postlist/家政服务.svg"), description: '同步微信资料xxx' },
    { id: 10, name: '寻人寻物', basePath: 'lost-found', icon: require("@/assets/postlist/寻人寻物.svg"), description: '同步微信资料xxx' },
    { id: 11, name: '爱心求助', basePath: 'charity-help', icon: require("@/assets/postlist/爱心求助.svg"), description: '同步微信资料xxx' }
];