// src/js/dataList.js

export const dataList = [
    {
        id: 1,
        section: '新闻',
        title: '新闻标题1',
        content: '这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板' +
            '块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容' +
            '示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板' +
            '块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内' +
            '容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例这是新闻板块的内容示例。',
        images: [
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-01'
    },
    {
        id: 2,
        section: '科技',
        title: '科技标题1',
        content: '这是科技板块的内容示例。',
        images: [
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-02'
    },
    {
        id: 3,
        section: '娱乐',
        title: '娱乐标题1',
        content: '这是娱乐板块的内容示例。',
        images: [],
        date: '2024-12-03'
    },
    {
        id: 4,
        section: '体育',
        title: '体育标题1',
        content: '这是体育板块的内容示例。',
        images: [
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-04'
    },
    {
        id: 5,
        section: '财经',
        title: '财经标题1',
        content: '这是财经板块的内容示例。',
        images: [
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-05'
    },
    {
        id: 6,
        section: '健康',
        title: '健康标题1',
        content: '这是健康板块的内容示例。',
        images: [],
        date: '2024-12-06'
    },
    {
        id: 7,
        section: '教育',
        title: '教育标题1',
        content: '这是教育板块的内容示例。',
        images: [
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-07'
    },
    {
        id: 8,
        section: '旅游',
        title: '旅游标题1',
        content: '这是旅游板块的内容示例。',
        images: [
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-08'
    },
    {
        id: 9,
        section: '时尚',
        title: '时尚标题1',
        content: '这是时尚板块的内容示例。',
        images: [
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-09'
    },
    {
        id: 10,
        section: '汽车',
        title: '汽车标题1',
        content: '这是汽车板块的内容示例。',
        images: [
            'https://via.placeholder.com/150'
        ],
        date: '2024-12-10'
    }
];
