import {createRouter, createWebHistory} from 'vue-router'
import Home from '../components/views/Home.vue'
import store from '@/store'
import PostCreationPage from "@/components/post/PostCreationPage";
import Chat from "@/components/home/Chat";
import Chatx from "@/components/home/Chatx";
import PublishRule from "@/components/home/PublishRule";
import UserServiceTerms from "@/components/home/UserServiceTerms";
import PostList from "@/components/post/PostCategoryBrowser";

import SwipeTest from "@/test/SwipeTest"
import AnnouncementTest from "@/test/AnnouncementTest";
import TagTest from "@/test/TagTest";
import PostCategoryBrowser from "@/components/post/PostCategoryBrowser";
import ParentComponent from "@/test/ParentComponent";
import PostWrapperComponent from "@/components/post/PostWrapperComponent";
import FakeLogin from "@/components/home/FakeLogin.vue";
import FakeRegister from "@/components/home/FakeRegister.vue";
import Feedback from "@/components/home/Feedback.vue";
import HistoryPage from "@/components/home/HistoryPage.vue";
import {dataList} from "@/js/dataList";
import DataDetail from "@/DataView/DataDetail.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        redirect:'/first',
        component: Home,
        children: [
            {
                path: 'first',
                name: 'first',
                component: () => import('../components/home/First.vue')
            },
            {
                path: '/detail/:id',
                name: 'DataDetail',
                component: DataDetail,
                props: route => ({
                    item: dataList.find(data => data.id === parseInt(route.params.id))
                })
            },

            {
                path: 'subscribe',
                name: 'subscribe',
                component: () => import('../components/home/Subscribe.vue')
            },
            {
                path: 'contactMe',
                name: 'contactMe',
                component: () => import('../components/home/ContactMe.vue')
            },
            {
                path: 'joinChat',
                name: 'joinChat',
                component: () => import('../components/home/JoinChat.vue')
            },
            {
                path: 'personalInfo',
                name: 'personalInfo',
                component: () => import('../components/home/PersonalInfo.vue')
            },
            {
                path: 'seekJobs',
                name: 'seekJobs',

                component: () => import('../components/home/SeekJobs.vue')
            },
            {
                path: 'favorites',
                name: 'favorites',
                component: () => import('../components/home/Favorites')
            },
            {
                path: 'messages',
                name: 'messages',
                component: () => import('../components/home/MessageBar')
            },
        ]
    },

    {
        path: '/test',
        name: "test",
        component: () => import('../test/Test.vue')
    },
    {
        path: '/connectTest',
        name: "connectTest",
        component: () => import('../test/connectTest')
    },
    {
        path: '/emailTest',
        name: "emailTest",
        component: () => import('../test/emailTest')
    },
    {
        path: '/selectPublishItem',
        name: "selectPublishItem",
        component: () => import('../components/views/SelectPublishItem.vue')
    },
    {
        path: '/xx',
        name: "xx",
        component: PublishRule
    },

    {
        path: '/selectPublishItem/:basePath',  // 动态路径，接收 basePath
        name: 'PostCreationPage',
        component: PostCreationPage,
        props: true, // 启用 props 传递，将路由参数作为 props 传递给组件
    },

    {
        path: '/userServiceTerms',
        name: 'userServiceTerms',
        component: UserServiceTerms
    },

    {
        path: '/PostSuccess',
        name: "PostSuccess",
        component: () => import('../components/post/PostSuccess'),
        props: route => ({
            postId: route.params.postId,   // 帖子 ID
            basePath: route.params.basePath,   // 当前板块，如 jobs, rentals
            mode: route.query.mode||'view',  // 通过 query 参数获取 mode，默认是查看模式
        }),
    },
    {
        path: '/PostDetail',
        name: 'PostDetail',
        component: () => import('@/components/post/PostDetail.vue'),
        props: route => ({ post: route.params.post, basePath: route.params.basePath })
    },
    {
        path: '/PostList',
        name: 'PostPostList',
        component: PostList
    },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat
    },
    {
        path: '/chatx',
        name: 'Chatx',
        component: Chatx
    },
    {
        path: '/SwipeTest',
        name: 'SwipeTest',
        component: SwipeTest
    },
    {
        path: '/AnnouncementTest',
        name: 'AnnouncementTest',
        component: AnnouncementTest
    },
    {
        path: '/TagTest',
        name: 'TagTest',
        component: TagTest
    },
    {
        path: '/PostCategoryBrowser',
        name: 'PostCategoryBrowser',
        component: PostCategoryBrowser
    },
    {
        path: '/post/:basePath/:postId',
        name: 'PostWrapperComponent',
        component: PostWrapperComponent,
        props: route => ({
            postId: route.params.postId,   // 帖子 ID
            basePath: route.params.basePath,   // 当前板块，如 jobs, rentals
            mode: route.query.mode||'view',  // 通过 query 参数获取 mode，默认是查看模式
        }),
    },
    {
        path: '/login',
        name: 'FacebookLogin',
        component: FakeLogin
    },
    {
        path: '/register',
        name: 'FakeRegister',
        component: FakeRegister
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: Feedback
    },
    {
        path: '/history-page',
        name: 'HistoryPage',
        component: HistoryPage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    base:'/main'
})

export default router
