<template>
  <div class="category-post-browser">
    <!-- 主要分类 -->
    <van-tabs
        v-model:active="activeCategory"
        swipeable
        :scrollspy="false"
        sticky
    >
      <van-tab
          v-for="category in categories"
          :key="category.name"
          :title="category.name"
      >
        <!-- 子分类（如果存在） -->
        <template v-if="category.subcategories && category.subcategories.length">
          <van-tabs
              v-model:active="activeSubCategories[category.name]"
              :swipeable="false"
              :scrollspy="false"
              sticky
          >
            <!-- 动态添加 '全部' 子分类 -->
            <van-tab
                key="全部"
                title="全部"
            />

            <!-- 渲染子分类 -->
            <van-tab
                v-for="subCategory in category.subcategories"
                :key="subCategory.name"
                :title="subCategory.name"
            />
          </van-tabs>
        </template>

        <!-- 使用 CategoryPostList 组件 -->
        <category-post-list
            :basePath="getActiveBasePath(category)"
        />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {ref, reactive, watch} from 'vue';
import CategoryPostList from './CategoryPostList.vue';
import {postCategories} from '@/js/postCategories';
import {useRoute} from "vue-router";

export default {
  name: 'CategoryPostBrowser',
  components: {
    CategoryPostList
  },
  setup() {
    const activeCategory = ref(0);
    const activeSubCategories = reactive({});
    const route = useRoute();
    const categories = postCategories;

    // 初始化每个有子分类的类别的activeSubCategories
    categories.forEach(category => {
      if (category.subcategories && category.subcategories.length) {
        activeSubCategories[category.name] = 0; // 默认选中 '全部'
      }
    });

    activeCategory.value = Number(route.query.id);
    const getActiveBasePath = (category) => {
      if (category.subcategories && category.subcategories.length) {
        const activeSubCategoryIndex = activeSubCategories[category.name];

        // 如果选中的是 '全部'，返回父分类的 basePath
        if (activeSubCategoryIndex === 0) {
          return category.basePath;
        }

        // 否则返回子分类的 basePath
        const subCategory = category.subcategories[activeSubCategoryIndex - 1]; // 因为 '全部' 是第0个
        return subCategory.basePath;
      }

      // 没有子分类时，返回父分类的 basePath
      return category.basePath;
    };

    // 监听路由的变化，改变 activeCategory
    watch(() => route.query.id, (newId) => {
      activeCategory.value = Number(newId);
    });

    return {
      activeCategory,
      activeSubCategories,
      categories,
      getActiveBasePath,
    };
  }
};
</script>

<style scoped>
.category-post-browser {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
