<template>
  <div class="search-container">
    <transition name="slide-fade">
      <div v-if="!isSearchActive" class="title">重庆酷喵喵科技有限公司</div>
    </transition>
    <div class="search-wrapper" :class="{ 'active': isSearchActive }">
      <van-field
          v-show="isSearchActive"
          v-model="searchText"
          placeholder="请输入搜索内容"
          class="search-input"
      />
      <van-icon name="search" @click="toggleSearch" class="search-icon" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Field, Icon } from 'vant';

export default {
  components: {
    [Field.name]: Field,
    [Icon.name]: Icon,
  },
  setup() {
    const searchText = ref('');
    const isSearchActive = ref(false);

    const toggleSearch = () => {
      isSearchActive.value = !isSearchActive.value;
      if (!isSearchActive.value) {
        searchText.value = '';
      }
    };

    return {
      searchText,
      isSearchActive,
      toggleSearch,
    };
  },
};
</script>

<style scoped>
.search-container {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #f7f8fa;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 50px;
  background-color: #f7f8fa;
}

.search-wrapper.active {
  width: 100%;
  background-color: #fff;
}

.search-input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  max-width: 0;
  opacity: 0;
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.search-wrapper.active .search-input {
  max-width: calc(100% - 50px);
  opacity: 1;
}

.search-input :deep(.van-field__control) {
  font-size: 16px;
  color: #333;
}

.search-icon {
  flex-shrink: 0;
  font-size: 24px;
  padding: 0 15px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: #1989fa;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>