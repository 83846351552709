<template>
  <div class="data-item" @click="viewDetail">
    <div class="data-item-content">
      <h4 class="centered">{{ item.title }}</h4>
      <h3>板块 {{ item.section }}</h3>
      <p v-html="formattedContent"></p>
      <small>{{ item.date }}</small>
    </div>
    <div class="data-item-images">
      <img v-for="(image, index) in item.images" :key="index" :src="image" alt="Data Image" class="data-item-image"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedContent() {
      // 将内容中的换行符替换为 <br> 标签
      return this.item.content.replace(/\n/g, '<br>');
    }
  },
  methods: {
    viewDetail() {
      this.$router.push({ name: 'DataDetail', params: { id: this.item.id } });
    }
  }
}
</script>

<style scoped>
.data-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.data-item-content {
  margin-bottom: 10px;
}

.data-item-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.data-item-image {
  width: 200px;
  height: 200px;
}

.centered {
  text-align: center; /* 使标题居中 */
}

.data-item-content h3 {
  margin: 5px 0;
  font-size: 1.8em;
  color: #427585; /* 设置板块名称的文字颜色 */
}

.data-item-content h4 {
  margin: 5px 0;
  font-size: 2em;
}

.data-item-content p {
  margin: 10px 0; /* 增加上下间距 */
  font-size: 1.5em;
  line-height: 1.6; /* 设置行高以扩大文字之间的间隔 */
  white-space: pre-wrap; /* 保留空白符号并支持换行 */
}

.data-item-content small {
  color: #888;
}
</style>
