import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {index} from './store'
import getVant from './plugins'
import weui from 'weui.js';
import 'vant/lib/index.css';
import '../public/css/responsive.css'
import 'weui';
const app=createApp(App)
getVant(app)
app.config.globalProperties.$weui = weui;
app.use(index)
app.use(router).mount('#app')
