<template>
  <div id="app">
    <router-view/>
    <footer class="footer">
      <div class="beian-content">
        <img src="@/assets/beian.png" alt="备案" class="beian-img" />
        <p class="beian-text">
          ICP备案号：
          <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2024044982号</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Home from "@/components/views/Home.vue"
import '../public/css/responsive.css'  // 引入响应式样式

export default {
  components: {
    Home,
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 12px;
}

.beian-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.beian-img {
  width: 20px; /* 调整图片大小 */
  height: 20px;
  margin-right: 8px; /* 图片和文字之间的间距 */
}

.beian-text {
  font-size: 14px;
}

.footer a {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>
