<template>
  <div class="post-card" @click="onPostClick">
    <PostHeader
        :nickname="post.nickname"
        :createdAt="post.createdAt"
        :avatarSrc="require('@/assets/user.jpg')"
    />

    <div class="post-content">{{ post.message }}</div>



    <TagSelector v-if="post.tags"
        :tags="displayTags"
        :mode="mode"
        :selectedTags="post.tags"
    />


    <img
        width="100"
        height="100"
        src="@/assets/footerlist/在线客服.svg"
    />
    <img
        width="100"
        height="100"
        src="@/assets/image/weather/晴.png"
    />



    <div class="post-details">
      <div class="location">
        <van-icon name="location-o"/>
        <span>{{ post.location }}</span>
      </div>
      <div v-if="post.salaryMin && post.salaryMax" class="salary">
        工资: <span class="salary-range">¥{{ post.salaryMin }} - ¥{{ post.salaryMax }}</span>
      </div>
    </div>


    <div class="post-footer">
      <div class="post-actions">
        <van-icon name="star-o" class="action-icon" @click.stop="onFavorite"/>
        <van-icon name="good-job-o" class="action-icon" @click.stop="onLike"/>
        <van-icon name="eye-o" class="action-icon" @click.stop="onView"/>
      </div>
      <div class="post-contact" @click.stop="onCallClick">
        <van-icon name="contact-o" size="1.2rem" class="phone-icon" />
        <span class="contact-name">{{ post.nickname}}</span>
        <van-icon name="phone-o" size="1.2rem" class="phone-icon"/>
        <van-tag plain type="primary" class="tel-tag">{{ formattedTel }}</van-tag>
      </div>
    </div>
  </div>


</template>

<script>
import router from "@/router";
import TagSelector from "@/components/post/item/TagSelector";
import { ref, onMounted } from 'vue';
import PostHeader from "@/components/post/item/PostHeader";


export default {
  components: {
    PostHeader,
    TagSelector,
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      required: true
    },
  },
  computed: {
    formattedTel() {
      return this.post.tel ? this.post.tel.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : '';
    }
  },

  setup(props) {
    const displayTags = ref([]);
    const mode = ref("view");
    const updateSelectedTags = (newTags) => {
      props.post.tags = newTags;
      updateDisplayTags();
    };

    const updateDisplayTags = () => {
      console.error("添加tags")
      const selectedTagNames = props.post.tags.split(',').filter(tag => tag.trim() !== '');
      displayTags.value = selectedTagNames.map(name => ({name, selected: true}));
    }

    onMounted(() => {
      updateDisplayTags();
    });

    return {
      mode,
      displayTags,
    }
  },

  methods: {
    onPostClick() {
      router.push({
        name: 'PostWrapperComponent',
        params: {
          basePath: this.basePath,
          postId: this.post.postId,
        },
        query: {
          mode: 'view',
        },
      });
    },
    onFavorite() {
      console.error(this.post.tags)
      // 实现收藏功能
      console.log('收藏功能待实现');
    },
    onLike() {
      // 实现点赞功能
      console.log('点赞功能待实现');
    },
    onView() {
      // 实现查看功能
      console.log('查看功能待实现');
    },
    onCallClick() {
      if (this.post.tel) {
        window.location.href = `tel:${this.post.tel}`;
      } else {
        console.log('没有可用的电话号码');
      }
    }
  }
};
</script>

<style scoped>
.post-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
}



.post-content {
  display: -webkit-box;           /* 创建弹性盒子模型 */
  -webkit-line-clamp: 3;          /* 设置显示三行 */
  -webkit-box-orient: vertical;   /* 垂直排列子元素 */
  overflow: hidden;               /* 超出部分隐藏 */
  text-overflow: ellipsis;        /* 超出部分显示省略号 */
  line-height: 1.5em;             /* 设置行高 */
  max-height: 4.5em;              /* 三行文本的最大高度 */
  margin-bottom: 3px;
  font-size: 1rem;
  font-weight: 450;
}

.post-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

.location, .salary {
  display: flex;
  align-items: center;
}

.location .van-icon {
  margin-right: 4px;
}

.salary-range {
  font-weight: bold;
  color: #1989fa;
}

.post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post-actions {
  display: flex;
  gap: 12px;
}

.action-icon {
  font-size: 1.2rem;
  color: #666;
  cursor: pointer;
}

.action-icon:hover {
  color: #1989fa;
}

.post-contact {
  display: flex;
  align-items: center;
}



.contact-name {
  font-size: 0.9rem;
  color: #666;
  margin-right: 8px;
}

.phone-icon {
  margin-right: 8px;
  cursor: pointer;
  color: #1989fa;
}

.tel-tag {
  font-size: 0.9rem;
}
</style>