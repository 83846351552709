<template>
  <div class="weather-container" :style="backgroundStyle">
    <div class="weather-header">
      <div class="weather-location">
        <van-icon name="location-o" class="weather-location-icon"/>
        <h5 class="weather-text">{{ weatherData.location }}</h5>
      </div>
      <h5 class="weather-text">{{ weatherData.currentXtime }} 更新</h5>
    </div>

    <div class="weather-temperature-container">
      <h1 class="weather-temperature" :style="{ color: temperatureColor }">
        {{ weatherData.temperatureValue }}°C
      </h1>
    </div>

    <div class="weather-air-container">
      <h5 class="weather-air-quality">
        空气指数：{{ weatherData.aqiChn }} 优
      </h5>
      <h5 class="weather-pm25">
        PM2.5：{{ weatherData.pm25Value }} 体感 {{ weatherData.apparentTemperatureValue }}°C
      </h5>
    </div>

    <h5 class="weather-description">
      {{ weatherData.forecastKeypoint }}
    </h5>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weatherData: {
        location: "重庆",
        currentXtime: new Date().toLocaleString(), // 获取当前时间
        temperatureValue: 22,
        apparentTemperatureValue: 24,
        forecastKeypoint: "晴天",
        aqiChn: 50,
        pm25Value: 15,
        skyconValue: "CLEAR_DAY"
      }
    };
  },
  computed: {
    backgroundStyle() {
      let bgUrl; // 初始化变量
// 根据天气情况动态选择背景图片
      switch (this.weatherData.skyconValue) {
        case 'CLEAR_DAY':
          bgUrl =  require('@/assets/weather/weather_rain.png');// 晴天图片
          break;
        case 'CLEAR_NIGHT':
          bgUrl = require('@/assets/weather/weather_rain.png'); // 晚上的图片
          break;
        case 'PARTLY_CLOUDY_DAY':
        case 'PARTLY_CLOUDY_NIGHT':
          bgUrl =  require('@/assets/weather/weather_rain.png');// 部分多云的图片
          break;
        case 'CLOUDY':
          bgUrl =  require('@/assets/weather/weather_rain.png');// 多云的图片
          break;
        case 'RAIN':
          bgUrl =  require('@/assets/weather/weather_rain.png');// 雨天的图片
          break;
        case 'SNOW':
          bgUrl = require('@/assets/weather/weather_rain.png'); // 雪天的图片
          break;
        default:
          bgUrl = require('@/assets/weather/weather_rain.png'); // 默认的图片
          break;

      }
      return {
        backgroundImage: `url(${bgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      };
    },
    temperatureColor() {
      const temp = this.weatherData.temperatureValue;
      const minTemp = -3;
      const maxTemp = 42;
      const clampedTemp = Math.max(minTemp, Math.min(temp, maxTemp));
      const hue = ((clampedTemp - minTemp) / (maxTemp - minTemp)) * 240;
      return `hsl(${hue}, 100%, 50%)`;
    }
  }
};
</script>

<style lang="less" scoped>
.weather-container {
  height: 100%;
  width: 100%;
}

.weather-header {
  display: flex;
  justify-content: space-between;
  padding: 1% 3%;
  color: white;
}

.weather-location {
  display: flex;
  align-items: center;
  color: white;
}

.weather-location-icon {
  font-size: 1.2rem;
}

.weather-text {
  color: white;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.weather-temperature-container {
  display: flex;
  justify-content: center;
}

.weather-temperature {
  text-align: center;
  font-size: 1.8rem;
}

.weather-description {
  margin-top: -3%;
  text-align: center;
  color: white;
  font-size: 0.9rem;
}

.weather-air-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weather-air-quality, .weather-pm25 {
  text-align: center;
  color: white;
  font-size: 0.9rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
</style>
